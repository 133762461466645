/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

import React from "react";
import { StyledProductImage } from "../../elements/product-image";

interface ProductImageProps {
  fullImage: string;
  isAngleImage?: boolean;
  heroimage?: boolean;
  alt: string;
  isThumbnail?: boolean;
  isSelected?: boolean;
  onClick?: any;
  handleImageError?: (index: any) => void;
  index?: any;
}

/**
 * Product image component
 * @param fullImage
 * @param isAngleImage
 * @param alt
 * @param props
 * @param heroimage
 */
export const ProductImage: React.FC<ProductImageProps> = ({
  fullImage,
  isAngleImage,
  heroimage,
  alt,
  isSelected,
  isThumbnail,
  handleImageError,
  index,
  ...props
}) => {
  const className = `${isThumbnail ? "thumbnail" : ""} ${isSelected ? "selected" : ""}`;
  const loadingImageFailed = () => {
    handleImageError?.(index);
  };
  // console.log(heroimage, "heroimage");
  if (fullImage && !isAngleImage && !isThumbnail && !heroimage) {
    return (
      <StyledProductImage
        itemProp="image"
        src={fullImage.replace(".jpg", "_aws_640.jpg")}
        alt={alt}
        className={className}
        onError={loadingImageFailed}
      />
    );
  } else if (fullImage && !isAngleImage && !isThumbnail && heroimage) {
    return (
      <StyledProductImage
        itemProp="image"
        src={fullImage}
        alt={alt}
        className={className}
        onError={loadingImageFailed}
      />
    );
  } else if (fullImage && !isAngleImage && isThumbnail) {
    return (
      <StyledProductImage
        itemProp="image"
        src={fullImage.replace(".jpg", "_aws_120.jpg")}
        alt={alt}
        className={className}
        onError={loadingImageFailed}
      />
    );
  } else {
    return null;
  }
};
