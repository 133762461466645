/*
 ***************************************************
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 ***************************************************
 */
import React, { useState, useEffect } from "react";
import { StyledTypography } from "../../elements/typography";
import { StyledLink } from "../../elements";
import axios from "axios";

interface ProductAttributeProps {
  title: string;
  element: any;
  skuColor?: string;
  attributeType?: string;
  currentSelection?: any;
  techSpecUrl?: string;
  page?: string;
  inventoryCount?: any;
}

export const ProductAttribute: React.FC<ProductAttributeProps> = ({
  title,
  element,
  skuColor,
  attributeType,
  currentSelection,
  techSpecUrl,
  page,
  inventoryCount,
  ...props
}: any) => {
  const showSkuColor = attributeType === "asgswatchcolor" ? true : false;
  const showSkusize = attributeType === "asgswatchsize" ? true : false;
  const [styleMeasureExists, setStyleMeasureExists] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = techSpecUrl;
      try {
        const response = await axios.get(url);
        if (response.status === 200 && !response.data.toLowerCase().includes("invalid url")) {
          setStyleMeasureExists(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [techSpecUrl]);
  console.log(inventoryCount, "inventoryCount");

  return (
    <>
      <StyledTypography variant="body2" className="product-color">
        {title == "Available Sizes" ? "Sizes" : title}
        {showSkuColor && (
          <>
            <span className="text-title-grey ">: {currentSelection.selectedAttributes.asgswatchcolor}</span>
            {inventoryCount != undefined && (
              <span className="padding-left-5 ">
                <>
                  {inventoryCount > 10 && <span className="green"> &#40;10&#43; In stock&#41; </span>}
                  {inventoryCount == 10 && <span className="green"> &#40;10 In stock&#41; </span>}
                  {inventoryCount < 10 && inventoryCount > 1 && (
                    <span className="red"> &#40;{inventoryCount} Left in stock, order soon&#41; </span>
                  )}
                  {inventoryCount == 0 && <span className="red"> &#40;Out of stock&#41; </span>}
                </>
              </span>
            )}
          </>
        )}
        {showSkusize && <span className="text-title-grey">: {currentSelection.selectedAttributes.asgswatchsize}</span>}

        {showSkusize && page !== "plp-quickinfo" && (
          <>
            <div className="size-measurement">
              <span className="size-guide">
                <StyledLink to="/sizes-and-fits" target={"_blank"} className="link-text">
                  Size Guide
                </StyledLink>
              </span>
              {styleMeasureExists && (
                <>
                  <span className="diveder-pipe"></span>
                  <span className="size-measure left-margin-2">
                    <a href={techSpecUrl} target={"_blank"} className="link-text">
                      Style Measurements
                    </a>
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </StyledTypography>
      <>{element}</>
    </>
  );
};
