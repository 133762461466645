import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { StyledGrid } from "../../elements";
import { useEffect, useState } from "react";

interface RatingReviewQAWidgetProps {
  productRatingreviewQA: any;
}
export const RatingReviewQAWidget: React.FC<RatingReviewQAWidgetProps> = (props: any) => {
  const { productRatingreviewQA } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [heightClass, setheightClass] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    setTimeout(() => {
      const divHeight = document.getElementById("accodion_Rating&Reviews")?.offsetHeight;
      console.log(divHeight, "offsetHeight");
      if (divHeight) {
        setheightClass(divHeight);
      }
    }, 2000);
  }, []);
  const AccordionHeight = (val) => {
    if ((val = "panel1a-header_0")) {
      setTimeout(() => {
        const divHeight = document.getElementById("accodion_Rating&Reviews")?.offsetHeight;
        //console.log(divHeight, "offsetHeight");
        if (divHeight) {
          setheightClass(divHeight);
        }
      }, 500);
    }
  };
  return (
    <>
      <StyledGrid md={12} className="rivewQuesWpr">
        <Box>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange} className="justify-center" centered>
              <Tab label="Rating & Reviews" />
              <Tab label="Q&A" />
            </Tabs>
          </Box>
          <Box sx={{ padding: 2 }}>
            {tabIndex === 0 && (
              <Box>
                {productRatingreviewQA.map((v: any, index: number) => (
                  <div className="pdp-details-accordin">
                    <div>
                      {v.title === "Rating & Reviews" && (
                        <Typography>
                          <span
                            id={`accodion_${v.title.replace(/ /g, "")}`}
                            className="display-inline"
                            style={{ minHeight: heightClass }}
                            key={index}>
                            {v.tabContent}
                          </span>
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </Box>
            )}
            {tabIndex === 1 && (
              <Box>
                {productRatingreviewQA.map((v: any, index: number) => (
                  <div className="pdp-details-accordin">
                    <div>
                      {v.title === "Q&A" && (
                        <Typography>
                          <span
                            id={`accodion_${v.title.replace(/ /g, "")}`}
                            className="display-inline"
                            style={{ minHeight: heightClass }}
                            key={index}>
                            {v.tabContent}
                          </span>
                        </Typography>
                      )}
                      {v.title !== "Q&A" && v.title !== "Rating & Reviews" && (
                        <Typography>
                          <span className="display-inline" key={index}>
                            {v.tabContent}
                          </span>
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </StyledGrid>
    </>
  );
};
