/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, Fragment, useEffect } from "react";

//UI
import {
  StyledGrid,
  StyledButton,
  StyledCheckbox,
  StyledChip,
  StyledSidebar,
  StyledFormControlLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledSwatch,
  StyledNumberInput,
  StyledLink,
} from "../../elements";

//import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useTheme } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

interface ProductFilterProps {
  onInputClick: (event: object) => void;
  toggleFacetLimit: (
    event: any, //: MouseEvent<HTMLAnchorElement>,
    facetValue: string
  ) => void;
  clearPriceFacet: () => void;
  validatePriceRange: () => boolean;
  onMaxPriceChange: (v: number) => void;
  onMinPriceChange: (v: number) => void;
  isFacetSelected: (v: string) => boolean;
  onFacetChange: (selection: string, label: string, Facetlabel: string) => void;
  showFacet: (facet: any) => boolean;
  isCategoryFacet: (facet: any) => boolean;
  isPriceFacet: (facet: any) => boolean;
  getFacetTitle: (facet: any) => string;
  onPriceSubmit: () => void;
  isSubmitButtonEnabled: boolean;
  facets: Array<any> | null;
  priceSelected: boolean;
  FACET_CATEGORY_VALUE_PREFIX: string;
  cid: string;
  priceLabel: string;
  maxPriceLabel: string;
  minPriceLabel: string;
  filterLabel: string;
  showMoreLabel: string;
  showLessLabel: string;
  filterByLabel: string;
  minPrice: number | null;
  maxPrice: number | null;
  formattedPriceDisplay: any;
  facetLengthMap: any;
  showMoreButton: any;
  incFacetLimit: any;
  decFacetLimit: any;
}

/**
 * Product Filter component
 * displays price, brand and other facets
 * @param props
 */
export const ProductFilterWidget: React.FC<ProductFilterProps> = (props: any, Refresh) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isActiveFacetId, setIsActiveFacetId] = useState<string>(() => "");

  const {
    onInputClick,
    toggleFacetLimit,
    showLessButton,
    clearPriceFacet,
    isSubmitButtonEnabled,

    validatePriceRange,
    onMaxPriceChange,
    onMinPriceChange,
    isFacetSelected,
    onFacetChange,
    showFacet,
    isCategoryFacet,
    isPriceFacet,
    getFacetTitle,
    facets,
    priceSelected,
    onPriceSubmit,
    FACET_CATEGORY_VALUE_PREFIX,
    cid,
    priceLabel,
    maxPriceLabel,
    minPriceLabel,
    filterByLabel,
    filterLabel,
    showMoreLabel,
    showLessLabel,
    minPrice,
    maxPrice,
    formattedPriceDisplay,
    isExpanded,
    toggleExpand,
    priceFacetMap,
    facetLengthMap,
    showMoreButton,
    incFacetLimit,
    decFacetLimit,
    searchSpringMerchandising,
    productListTotal,
  } = props;

  //checkbox includes
  const [state, setState] = React.useState({
    checkedB: true,
  });

  const [open, setOpen] = useState<boolean>(false);
  //checkbox includes
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const filterList = (
    <>
      <div className="plp-facet-wrap">
        {facets &&
          facets.map((facet: any, index: number) => (
            <StyledAccordion
              testId={`productFilter-facet-${facet.name}`}
              //defaultExpanded={!isMobile}
              defaultExpanded={true}
              key={facet.value}
              //expanded={!isMobile || isActiveFacetId === facet.value}
              expanded={isExpanded}
              onClick={toggleExpand}>
              <StyledAccordionSummary className="expand-icon" expandIcon={<ExpandMoreIcon />}>
                {facet.label}
              </StyledAccordionSummary>
              <StyledAccordionDetails onClick={toggleExpand}>
                {isPriceFacet(facet) ? (
                  <>
                    <StyledGrid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      {facet.values &&
                        facet.values.slice(0, facetLengthMap[facet.label]).map((entry: any, _: number) => (
                          <Fragment key={entry.value}>
                            <StyledFormControlLabel
                              className="top-margin-1 full-width checkbox-filter"
                              control={
                                <StyledCheckbox onChange={() => onFacetChange(entry.low, facet.label, entry.high)} />
                              }
                              label={`${entry.label} (${entry.count})`}
                            />
                          </Fragment>
                        ))}
                      {
                        <div className="top-margin-1 full-width show-more-less">
                          {facet.values && showMoreButton(facet) && (
                            <StyledLink
                              key={index}
                              className="go-link show-more"
                              id={`productFilter_a_26_${index}_${cid}`}
                              testId={`productFilter_${showMoreLabel}`}
                              onClick={(event) => incFacetLimit(event, facet, facetLengthMap)}>
                              {showMoreLabel}
                              <span className="material-icons-outlined more">south</span>
                            </StyledLink>
                          )}
                          {facet.values && showLessButton(facet) && (
                            <StyledLink
                              key={index}
                              className="go-link show-less"
                              id={`productFilter_a_27_${index}_${cid}`}
                              testId={`productFilter_${showLessLabel}`}
                              onClick={(event) => decFacetLimit(event, facet)}>
                              {showLessLabel}
                              <span className="material-icons-outlined less">north</span>
                            </StyledLink>
                          )}
                        </div>
                      }
                    </StyledGrid>
                  </>
                ) : (
                  <StyledGrid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {facet.values &&
                      facet.values.slice(0, facetLengthMap[facet.label]).map((entry: any, _: number) => (
                        <Fragment key={entry.value}>
                          {/* {entry.image ? ( */}
                          {facet.field === "asgswatchcolorfamily" ? (
                            <div className="color-swatches">
                              <StyledSwatch
                                style={{
                                  backgroundImage: `url("https://static.augustasportswear.com/color/${entry.value}.png")`,
                                  // backgroundImage: `url("${entry.image}")`,
                                }}
                                onClick={() => onFacetChange(entry.value, facet.label)}
                                size="medium"
                                selected={isFacetSelected(entry.value)}
                                title={entry.label}
                                data-testid={`product-filter-${entry.value.toLowerCase()}-swatch`}
                              />
                              <div className="count-label">
                                <span className="color-label">
                                  <small>{entry.label}</small>
                                </span>
                                <span className="color-count">
                                  <small>&#40;{entry.count}&#41;</small>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <StyledFormControlLabel
                              className="top-margin-1 full-width filter-category checkbox-filter"
                              control={
                                <StyledCheckbox
                                  checked={isFacetSelected(entry.value)}
                                  onChange={() => onFacetChange(entry.value, facet.label)}
                                />
                              }
                              label={`${entry.label} (${entry.count})`}
                            />
                          )}
                        </Fragment>
                      ))}
                    {
                      <div className="top-margin-1 full-width show-more-less">
                        {facet.values && showMoreButton(facet) && (
                          <StyledLink
                            key={index}
                            className="go-link show-more"
                            id={`productFilter_a_26_${index}_${cid}`}
                            testId={`productFilter_${showMoreLabel}`}
                            onClick={(event) => incFacetLimit(event, facet, facetLengthMap)}>
                            {showMoreLabel}
                            <span className="material-icons-outlined more">south</span>
                          </StyledLink>
                        )}
                        {facet.values && showLessButton(facet) && (
                          <StyledLink
                            key={index}
                            className="go-link show-less"
                            id={`productFilter_a_27_${index}_${cid}`}
                            testId={`productFilter_${showLessLabel}`}
                            onClick={(event) => decFacetLimit(event, facet)}>
                            {showLessLabel}
                            <span className="material-icons-outlined less">north</span>
                          </StyledLink>
                        )}
                      </div>
                    }
                  </StyledGrid>
                )}
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        <Hidden mdUp>
          <div className="filter-footer-mobile">
            <div className="search-results text-center">
              <span>Show {productListTotal} results</span>
            </div>
            {/* <StyledButton testId="apply-filter-mobile" color="primary" fullWidth onClick={() => setOpen(!open)}>
              Apply Filter
            </StyledButton> */}
          </div>
        </Hidden>
      </div>
    </>
  );

  return facets && facets.length > 0 ? (
    <div className="plp-filter-wrapper">
      <StyledSidebar
        title={filterByLabel}
        sidebarContent={filterList}
        breakpoint="md"
        className="product-filter plp-product-filter"
        scrollable={true}
        isMobileFacetOpen={true}
        page="leftfacet"
      />
    </div>
  ) : null;
};
