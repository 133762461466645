/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect } from "react";
import { ProductThumbnailSlider } from "./product-thumbnail-slider";

interface ProductThumbnailsProps {
  imageList: any[];
  changeMainImage: (index: number, image: string) => void;
  index?: number;
}

export const ProductThumbnails: React.FC<ProductThumbnailsProps> = (props: ProductThumbnailsProps) => {
  const { imageList, changeMainImage, index } = props;
  const [slidesList, setslidesList] = useState(imageList);

  useEffect(() => {
    setslidesList(imageList);
  }, [imageList]);
  const chooseImage = (index: number, heroImage: string) => {
    changeMainImage(index, heroImage);
  };
  return <ProductThumbnailSlider {...{ slidesList, chooseImage, index, setslidesList }} />;
};
