/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import HTMLReactParser from "html-react-parser";
import styled from "styled-components";
//UI
import { StyledProductRecommendationSlider, StyledTypography } from "../../elements";
import { EMarketingSpotWidgetProps } from "../../types";

import { QuickInfoProvider } from "../../commerce-widgets/catalog-entry-list/quickinfo-context";

const StyledProductRecommendationLayout = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(4)}px 0;
  `}
`;

/**
 * product recommendation widget.
 * For props definition, @see {@link EMarketingSpotWidgetProps}.
 * @param props The props for `ProductRecommendationWidget`, which contains an eSpot object.
 */
const ProductRecommendationWidget: React.FC<EMarketingSpotWidgetProps> = ({ eSpot, page, ...props }) => {
  const slides = eSpot.catEntry.slides;
  const recommendedProductTitle = eSpot.catEntry.title;
  const QuickViewModal = props["quickViewModal"];
  const requestPage = page?.name;

  return (
    <QuickInfoProvider>
      {QuickViewModal}
      <StyledProductRecommendationLayout>
        {slides && slides.length > 0 ? (
          <>
            <div className="product-recomm-widget">
              {recommendedProductTitle && (
                <StyledTypography variant="h4">{HTMLReactParser(recommendedProductTitle)}</StyledTypography>
              )}
              <StyledProductRecommendationSlider slidesList={slides} page={requestPage} />
            </div>
          </>
        ) : null}
      </StyledProductRecommendationLayout>
    </QuickInfoProvider>
  );
};

export { ProductRecommendationWidget };
