import React from "react";

function InlineRating(props) {
  var productList = props.productList;
  var elements = [];
  for (let i = 0; i < productList.length; i++) {
    elements.push(productList[i].product_number);
  }
  getInlineRatingsFromBV(elements);
  return "";
}
function getInlineRatingsFromBV(productId) {
  $BV.ui("rr", "inline_ratings", {
    productIds: productId,
    containerPrefix: "BVRRInlineRating",
  });
}
export default InlineRating;
