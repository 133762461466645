/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
//UI

import { StyledGrid, StyledContainer, StyledButton } from "../../elements";
import Grid from "@material-ui/core/Grid";
//types
import { PageLayoutProps } from "../..";

import { Hidden } from "@material-ui/core";
import HTMLReactParser, { HTMLReactParserOptions, attributesToProps } from "html-react-parser";
import parse from "html-react-parser";
import { Element } from "domhandler/lib/node";

/**
 * Product Listing Page Layout
 * @description Two columns with left filter layout.
 * @param cid Unique identifier for this component.
 * @param slots All the slots containing commerce widgets in this layout.
 */
export const ProductListingPageLayout: React.FC<PageLayoutProps> = ({
  cid,
  slots = [],
  primaryBannerRefresh,
  secondaryBannerRefresh,
  footerBannerRefresh,
  ...props
}) => {
  let primaryBannerContent = primaryBannerRefresh;
  let secondaryBannerContent = secondaryBannerRefresh;
  let footerBannerContent = footerBannerRefresh;
  if (primaryBannerContent === undefined) {
    primaryBannerContent = "";
  }
  if (secondaryBannerContent === undefined) {
    secondaryBannerContent = "";
  }
  if (footerBannerContent === undefined) {
    footerBannerContent = "";
  }
  const SlotOne = () => {
    return (
      <>
        {slots["1"] && (
          <>
            <StyledGrid item xs={12} md={12} className="plp-breadcrum">
              {slots["1"].map((e: any) => {
                const CurrentComponent = e.CurrentComponent;
                return <CurrentComponent key={e.key} />;
              })}
            </StyledGrid>
          </>
        )}
      </>
    );
  };

  const Plpheading = () => {
    return (
      <>
        <StyledGrid container item direction="row" className="text-center plp-page-title">
          <StyledGrid item xs={12} lg={12}>
            <h1>MENS' TOPS</h1>
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };

  const SSHeaderPrimaryBanner = () => {
    //const banner = document.getElementById("headerPrimaryBanner")?.innerHTML ?? "";

    // const options: HTMLReactParserOptions = {
    //   replace: (domNode) => {
    //     if (domNode instanceof Element && domNode.attribs && domNode.name === "main") {
    //       const props = attributesToProps(domNode.attribs);
    //       return <div {...props} />;
    //     }
    //   },
    // };

    // const reactElement = parse(banner, {
    //   replace: (node) => {
    //     if (node.type === 'script') {
    //       let externalScript = node.attribs?.src ? true : false;
    //       const script = document.createElement('script');
    //       if (externalScript) {
    //         script.src = node.attribs?.src;
    //       } else {
    //         script.innerHTML = node.children[0]?.data;
    //       }
    //       document.head.append(script);
    //     }
    //   }
    // });

    return (
      <>
        <StyledGrid container item direction="row" className="text-center plp-primary-banner">
          <StyledGrid item xs={12} lg={12}>
            {/* {HTMLReactParser(banner, options)} */}

            {HTMLReactParser(primaryBannerContent)}
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };
  const SSHeaderSecondaryBanner = () => {
    const banner = document.getElementById("headerSecondaryBanner")?.innerHTML ?? "";
    return (
      <>
        <StyledGrid container item direction="row" className="text-center plp-secondary-banner">
          <StyledGrid item xs={12} lg={12}>
            {HTMLReactParser(secondaryBannerContent)}
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };

  const SSFooterBanner = () => {
    const banner = document.getElementById("footerBanner")?.innerHTML ?? "";
    return (
      <>
        <StyledGrid container item direction="row" className="text-center plp-footer-banner">
          <StyledGrid item xs={12} lg={12}>
            {HTMLReactParser(footerBannerContent)}
          </StyledGrid>
        </StyledGrid>
      </>
    );
  };
  const Quickfilter = () => {
    return (
      <>
        <Grid container spacing={3}>
          <div className="plp-quick-filter-wrap">
            <div className="plp-quick-filter">
              <Grid item xs={2} lg={1}>
                <div className="product-container">
                  <a className="product-section" href="">
                    <span className="product-img active">
                      <img
                        src="https://static.augustasportswear.com/product/R20CPM_NAV_lquarter_aws_640.jpg"
                        alt=""></img>
                    </span>
                    <span className="product-category">
                      <span className="name">Compression Shirts</span>
                      <span className="count">(5)</span>
                    </span>
                  </a>
                </div>
              </Grid>
              <Grid item xs={2} lg={1}>
                <div className="product-container">
                  <a className="product-section" href="">
                    <span className="product-img">
                      <img
                        src="https://static.augustasportswear.com/product/222591_302_lquarter_aws_640.jpg"
                        alt=""></img>
                    </span>
                    <span className="product-category">
                      <span className="name">Long Sleeve</span>
                      <span className="count">(35)</span>
                    </span>
                  </a>
                </div>
              </Grid>
              <Grid item xs={2} lg={1}>
                <div className="product-container">
                  <a className="product-section" href="">
                    <span className="product-img">
                      <img
                        src="https://static.augustasportswear.com/product/223501_245_lquarter_aws_640.jpg"
                        alt=""></img>
                    </span>
                    <span className="product-category">
                      <span className="name">Short Sleeve</span>
                      <span className="count">(15)</span>
                    </span>
                  </a>
                </div>
              </Grid>
              <Grid item xs={2} lg={1}>
                <div className="product-container">
                  <a className="product-section" href="">
                    <span className="product-img">
                      <img
                        src="https://static.augustasportswear.com/product/222575_080_lquarter_aws_640.jpg"
                        alt=""></img>
                    </span>
                    <span className="product-category">
                      <span className="name">Tanks</span>
                      <span className="count">(195)</span>
                    </span>
                  </a>
                </div>
              </Grid>
              <Grid item xs={2} lg={1}>
                <div className="product-container">
                  <a className="product-section" href="">
                    <span className="product-img">
                      <img
                        src="https://static.augustasportswear.com/product/223501_245_lquarter_aws_640.jpg"
                        alt=""></img>
                    </span>
                    <span className="product-category">
                      <span className="name">View all</span>
                      <span className="count">(5)</span>
                    </span>
                  </a>
                </div>
              </Grid>
            </div>
          </div>
        </Grid>
      </>
    );
  };
  const SlotTwo = () => {
    const [show, setShow] = React.useState(false);
    return (
      <>
        {slots["2"] && (
          <StyledGrid item xs={12} md={3} lg={2} className={`${show ? "Hide-SideBar" : ""} sidebar plp-sidebar`}>
            {slots["2"].map((e: any) => {
              const CurrentComponent = e.CurrentComponent;
              return <CurrentComponent key={e.key} />;
            })}
          </StyledGrid>
        )}
        {slots["3"] && (
          <StyledGrid item xs={12} md={9} lg={10} className={show ? "show-Product-full" : ""}>
            <Hidden smDown>
              <StyledButton
                onClick={() => setShow(!show)}
                testId="plp-filter-btn"
                color="secondary"
                className={`${show ? "arrow-right" : ""} FilterBtn`}>
                Filter
              </StyledButton>
            </Hidden>
            {slots["3"].map((e: any) => {
              const CurrentComponent = e.CurrentComponent;
              return <CurrentComponent key={e.key} />;
            })}
          </StyledGrid>
        )}
      </>
    );
  };

  // const SlotThree = () => {
  //   return (
  //     <>
  //       {slots["3"] && (
  //         <StyledGrid item xs={12} md={9} className={show ? "show" : ""}>
  //           {slots["3"].map((e: any) => {
  //             const CurrentComponent = e.CurrentComponent;
  //             return <CurrentComponent key={e.key} />;
  //           })}
  //         </StyledGrid>
  //       )}
  //     </>
  //   );
  // };

  return (
    <StyledContainer id={cid} className="plp-whole-whr">
      {slots["1"] ? <SlotOne /> : null}
      <SSHeaderPrimaryBanner />
      <SSHeaderSecondaryBanner />
      {/* <Plpheading />
      <Quickfilter /> */}
      {slots["2"] || slots["3"] ? (
        <StyledGrid container spacing={2} className="plp-page-container">
          <SlotTwo />
          {/* <SlotThree /> */}
        </StyledGrid>
      ) : null}
      <SSFooterBanner />
    </StyledContainer>
  );
};
