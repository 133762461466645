/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, Fragment, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LazyComponentProps, trackWindowScroll } from "react-lazy-load-image-component";
import Drawer from "@material-ui/core/Drawer";
import InlineRating from "../../components/bv/InlineRating";

//UI
import {
  StyledGrid,
  StyledChip,
  StyledSelect,
  StyledPagination,
  StyledButton,
  StyledTypography,
  StyledFormControl,
  StyledLink,
  StyledCircularProgress,
} from "../../elements";

//custom select
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@material-ui/core";
import { keyBy } from "lodash-es";
import HTMLReactParser from "html-react-parser";

//
/**
 * Catalog entry list widget
 *  @summary Displays Product Card List in Grid Layout.
 * `@prop {any} props` have following properties:
 * `@property {String} cid(required)` Unique identifier used for layouts.
 * `@property {String} categoryId(required)` Category unique identifier.
 * `@property {String} searchTerm` The input string for search.
 * `@property {any} translation(required)` The translation JSON object for i18n.
 * `@property {any} ProductCards(required)` The wrapper component to display product list.
 * `@property {any} formattedPriceDisplay(required)` The wrapper element use to display price on product grid layout.
 * `@property {any} sortOptions(required)` The options to sort the products like Name, Brand etc.
 * `@property {any} onSortOptionChange `The function will sort the products like Name, Brand etc.
 * `@property {any} SEARCH(required)` The constant variable - "bySearchTerm".
 * `@property {any} pageLimit(required)`  The number of products to dislay in a single page for pagination.
 * `@property {any} selectedFacets(required)` The labels use to display selected facet name.
 * `@property {any} clearPriceFacet(required)` The handler to clear the price facets.
 * `@property {any} onPageChange(required)` The handler to go to next page - pagination.
 * `@property {any} isValidUrl(required)` The function will return true if URL is valid.
 */
interface CatalogEntryListWidgetProps extends LazyComponentProps {
  cid: string;
  categoryId?: string;
  searchTerm?: string;
  isValidUrl: any;
  onPageChange: any;
  clearPriceFacet: any;
  onSortOptionChange: any;
  onClearAll: any;
  onFacetRemove: any;
  priceSelected: any;
  selectedFacets: any;
  productListTotal: any;
  selectedSortOption: any;
  pageLimit: any;
  productList: any;
  sortOptions: any;
  selectedPageOffset: any;
  suggestedKeywords: any;
  SEARCH: any;
  formattedPriceDisplay: any;
  ProductCards?: any;
  translation?: any;
  selectFacetRemove: boolean;
  ssPriceFacetOptions: any;
  tagTerm: string;
}
/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */
const CatalogEntryListWidget: React.FC<CatalogEntryListWidgetProps> = (props: any) => {
  const {
    priceSelected,
    selectedFacets,
    selectedSortOption,
    sortOptions,
    selectedPageOffset,
    suggestedKeywords,
    SEARCH,
    formattedPriceDisplay,
    ProductCards,
    translation,
    onSortOptionChange,
    productListTotal,
    pageLimit,
    cid,
    onFacetRemove,
    onClearAll,
    categoryId,
    searchTerm,
    isValidUrl,
    onPageChange,
    clearPriceFacet,
    productList,
    selectFacetRemove,
    ssPriceFacetOptions,
    tagTerm,
    searchSpringMerchandising,
  } = props;

  const pageCountTotal: number = Math.ceil(productListTotal / pageLimit);
  const selectedPage = Math.floor(selectedPageOffset / pageLimit) + 1;
  const [optionval, setoptionval] = useState<string>("Best Inventory");
  const getval = (val) => {
    setoptionval(val);
  };
  //console.log(sortOptions[0].label,"sortOptions")
  //
  //const [showdrawer, setdrawer] = React.useState(false);
  //const toggleDrawer = () => setOpen(!setOpen)
  const [open, setOpenSort] = useState<boolean>(false);
  const to = (url: any) => {
    window.location.assign(url);
  };
  const searchSpringRedirects = searchSpringMerchandising?.redirect ?? "";
  const loading = productListTotal < 0;
  return (
    <>
      {/* <div id="headerPrimaryBanner" style={{ visibility: "hidden" }}>
        {searchSpringMerchandising && searchSpringMerchandising.content && searchSpringMerchandising.content.header
          ? Object.entries(searchSpringMerchandising.content.header).map((mer: any) => mer)
          : null}
      </div>
      <div id="headerSecondaryBanner" style={{ visibility: "hidden" }}>
        {searchSpringMerchandising && searchSpringMerchandising.content && searchSpringMerchandising.content.banner
          ? Object.entries(searchSpringMerchandising.content.banner).map((mer: any) => mer)
          : null}
      </div>
      <div id="footerBanner" style={{ visibility: "hidden" }}>
        {searchSpringMerchandising && searchSpringMerchandising.content && searchSpringMerchandising.content.footer
          ? Object.entries(searchSpringMerchandising.content.footer).map((mer: any) => mer)
          : null}
      </div> */}
      {loading ? (
        <div className="loader-icon-wpr">
          <StyledCircularProgress />
        </div>
      ) : null}
      <div className="product-listing-container productListingWidget">
        {productListTotal === 0 && searchTerm !== "" && !selectFacetRemove && searchSpringRedirects === "" ? (
          <div id={`productGrid_div_18_${cid}`} className="suggested-keywords search-suggested-words">
            <StyledTypography variant="h6" id={`productGrid_p_19_${cid}`} className="bottom-margin-2">
              {translation.ProductGridLabelsnoMatches}
            </StyledTypography>
            <StyledTypography variant="h5" id={`productGrid_p_21_${cid}`} className="bottom-margin-1">
              {translation.ProductGridLabelssearchAgain}
            </StyledTypography>
            <hr />
            <div className="searh-keyword-hints top-margin-2 bottom-margin-2">
              <span>
                <span className="material-icons icon">circle</span>Try different search items
              </span>
              <span>
                <span className="material-icons icon">circle</span>Check your spelling
              </span>
              <span>
                <span className="material-icons icon">circle</span>Use exact phrases
              </span>
            </div>
            {suggestedKeywords?.length > 0 && (
              <>
                <div className="search-did-mean">
                  <span className="material-icons-outlined icon">help_outline</span>
                  <span>{translation.ProductGridLabelssuggestion}</span>
                  {suggestedKeywords?.map((keyword: string, index: number) => (
                    <StyledLink
                      key={keyword}
                      to={SEARCH + "?searchTerm=" + keyword}
                      className="suggestion-link left-padding-1 right-padding-1"
                      id={`productGrid_a_22_${index}_${cid}`}
                      testId={`suggestedKeywords_${keyword}`}>
                      {keyword} <br />
                    </StyledLink>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : null}
        {searchSpringRedirects !== "" && to(searchSpringRedirects)}
        {/* Search result is 1, then go to PDP directly */}
        {productListTotal === 1 &&
          isValidUrl(productList) &&
          searchTerm !== "" &&
          Object.keys(selectedFacets)?.length === 0 &&
          !priceSelected &&
          !selectFacetRemove &&
          to(productList[0].url)}
        {productListTotal > 1 ? (
          <StyledGrid
            container
            className="bottom-margin-1 product-result"
            justifyContent="space-between"
            alignItems="center">
            <StyledGrid item>
              <StyledTypography variant="body2" className="total-product-shown">
                {categoryId !== "" || tagTerm !== "" ? translation.ProductGridLabelsproductFound : null}
                {categoryId === "" && tagTerm === "" ? translation.ProductGridLabelsproductSearchFound : null}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid container item style={{ width: "auto", alignItems: "center" }}>
              <StyledGrid item>
                <Hidden mdUp>
                  <div className="plp-mob-sortby">
                    <div className="sort-section">
                      <StyledButton
                        className="sortby-btn"
                        color="secondary"
                        onClick={() => setOpenSort(!open)}
                        testId="mobile-sortby">
                        {optionval}
                      </StyledButton>
                      <Drawer
                        anchor="bottom"
                        open={open}
                        onClose={() => setOpenSort(!open)}
                        className="plp-sortby-drawer">
                        <div className="title-sort">
                          <h6>Sort By</h6>
                          <span className="material-icons-outlined" onClick={() => setOpenSort(!open)}>
                            close
                          </span>
                        </div>

                        <div className="sortby-values">
                          {sortOptions?.map((option: any, index: number, key) => (
                            <li
                              data-value={option.field.concat("=").concat(option.direction)}
                              data-index={index}
                              key={option.field}
                              id={`productGrid_option_7_${index}_${cid}`}
                              onClick={(event: any) => {
                                onSortOptionChange(event, option.field.concat("=").concat(option.direction));
                                setOpenSort(!open);
                                getval(option.label);
                              }}>
                              {option.label}
                            </li>
                          ))}
                        </div>
                      </Drawer>
                    </div>
                  </div>
                </Hidden>
                <Hidden smDown>
                  <StyledFormControl variant="outlined">
                    <StyledSelect
                      data-testid="list-sort-option"
                      id={`productGrid_select_6_${cid}`}
                      value={selectedSortOption}
                      native
                      onChange={(event: any) => onSortOptionChange(event, "desktop")}
                      fullWidth>
                      {sortOptions?.map((option: any, index: number) => (
                        <option
                          hidden={
                            option.field.concat("=").concat(option.direction) === selectedSortOption ? true : false
                          }
                          value={option.field.concat("=").concat(option.direction)}
                          key={option.field}
                          id={`productGrid_option_7_${index}_${cid}`}>
                          {option.label}
                        </option>
                      ))}
                    </StyledSelect>
                  </StyledFormControl>
                </Hidden>
                {/*<FormControl>
          <Select
            data-testid="list-sort-option"
            labelId="demo-simple-select-label"
            id={`productGrid_select_6_${cid}`}
            value={selectedSortOption}
            onChange={(event: any) => onSortOptionChange(event)}>
            {sortOptions?.map((option: any, index: number) => (
               <MenuItem value={option.value} key={option.value} id={`productGrid_option_7_${index}_${cid}`}>
               {translation.optiontranslationKey[index]}
             </MenuItem>
              ))}
          </Select>
            </FormControl> */}
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        ) : null}
        {/* Facet selection listing */}
        {Object.keys(selectedFacets).length > 0 ? (
          <StyledGrid item container direction="row" alignItems="center" className="bottom-margin-3">
            <StyledTypography variant="body2">{translation.ProductGridLabelsfilteredBy}</StyledTypography>
            {Object.keys(selectedFacets).map((key: string, index: number) => (
              <Fragment key={key}>
                {selectedFacets[key] == "Price" ? (
                  <StyledChip
                    size="medium"
                    className="left-margin-1 plp-filter1"
                    label={ssPriceFacetOptions[key]}
                    onClick={() => onFacetRemove(key)}
                    onDelete={() => onFacetRemove(key)}
                    data-testid={`catalog-entry-list-${key}-selected-facet-chip`}
                  />
                ) : (
                  <StyledChip
                    size="medium"
                    className="left-margin-1 plp-filter1"
                    label={key}
                    onClick={() => onFacetRemove(key)}
                    onDelete={() => onFacetRemove(key)}
                    data-testid={`catalog-entry-list-${key}-selected-facet-chip`}
                  />
                )}
              </Fragment>
            ))}

            {Object.keys(selectedFacets).length > 1 && (
              <StyledButton testId="clear-all-facets" variant="text" className="left-margin-1 text-normal">
                <StyledLink
                  onClick={(event) => onClearAll(event)}
                  to=""
                  className="clear-all link-text"
                  testId="clear-all-facets">
                  {translation.ProductGridActionsclearAll}
                </StyledLink>
              </StyledButton>
            )}
          </StyledGrid>
        ) : null}
        {/* Product listing and pagination */}
        {ProductCards}
        {/* productListTotal > pageLimit && (
      <StyledPagination
        count={pageCountTotal}
        shape="rounded"
        page={selectedPage}
        onChange={(event: ChangeEvent<unknown>, value: number) => onPageChange(value)}
      />
    )*/}
        {productListTotal - selectedPageOffset > pageLimit ? (
          <Grid container direction="row" spacing={2} className="plp-show-more">
            <Grid item xs={12} lg={12} className="text-center">
              {productListTotal - (selectedPageOffset + pageLimit) > pageLimit ? (
                <StyledButton
                  testId="show-more-product"
                  color="secondary"
                  onClick={(event: ChangeEvent<unknown>, value: number) => onPageChange(selectedPage)}>
                  Show {pageLimit} more
                </StyledButton>
              ) : (
                <StyledButton
                  testId="show-more-product"
                  color="secondary"
                  onClick={(event: ChangeEvent<unknown>, value: number) => onPageChange(selectedPage)}>
                  Show {productListTotal - (selectedPageOffset + pageLimit)} more
                </StyledButton>
              )}
            </Grid>
          </Grid>
        ) : null}
        <InlineRating productList={productList}></InlineRating>
      </div>
    </>
  );
};
export default trackWindowScroll(CatalogEntryListWidget);
