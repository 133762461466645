/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
import React from "react";
//UI

import styled from "styled-components";
import { StyledProductRecommendationSlider, StyledProgressPlaceholder, StyledTypography } from "../../elements";
import { QuickInfoProvider } from "../../commerce-widgets/catalog-entry-list/quickinfo-context";

const StyledProductRecommendationLayout = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(4)}px 0;
  `}
`;

interface MerchandisingAssociationContentProps {
  slides: any;
  recommendedProdTitle: string;
}

/**
 * Merchandising association display widget.
 *
 * ` @prop { any } props` have following properties:
 * ` @property { any } slides (required)` The list of merchandise associated products.
 * ` @property { string } recommendedProdTitle (required)' The title of merchandise associated products.
 *
 * MerchandisingAssociationContent returns all the list of merchandise associated products.
 */

const MerchandisingAssociationContent: React.FC<MerchandisingAssociationContentProps> = (props: any) => {
  const slides = props.slides;
  const recommendedProdTitle = props.recommendedProdTitle;
  const QuickViewModal = props["quickViewModal"];
  return (
    <div className="product-widget-carousel">
      <QuickInfoProvider>
        {QuickViewModal}
        <StyledProductRecommendationLayout>
          {slides && slides.length > 0 ? (
            <>
              <StyledTypography variant="h4" className="text-center">
                {recommendedProdTitle}
              </StyledTypography>
              <StyledProductRecommendationSlider slidesList={slides} />
            </>
          ) : null}
        </StyledProductRecommendationLayout>
      </QuickInfoProvider>
    </div>
  );
};
export { MerchandisingAssociationContent };
