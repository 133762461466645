/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Slider, Slide, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

// Custom libraries
import { commonUtil } from "@hcl-commerce-store-sdk/utils";
import { StyledCarouselProvider } from "../../elements/carousel";
import { ProductImage } from "../product-image";
import axios from "axios";

interface ProductThumbnailSliderProps {
  slidesList: any[];
  chooseImage: (e: any, image: any) => any;
  index?: number;
  setslidesList: React.Dispatch<React.SetStateAction<any[]>>;
}

export const ProductThumbnailSlider: React.FC<ProductThumbnailSliderProps> = ({
  slidesList,
  chooseImage,
  index,
  setslidesList,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const mobile = isSm || isXs;
  const visibleSlides = 6;
  /*const clickSlide = (e: any) => {
    chooseImage(e);
  };*/

  let slideWidth = 10;
  let slideHeight = 10;

  if (isSm) {
    slideWidth = 5;
    slideHeight = 3;
  }

  if (isXs) {
    slideWidth = 1;
    slideHeight = 1;
  }
  const handleErrorImage = (indexNo: any) => {
    const updatedSlidesList = slidesList.filter((slide, index) => index !== indexNo);
    setslidesList(updatedSlidesList);
  };
  const handleHeroImage = (indexValue, fullImg) => {
    const heroImage = commonUtil.getThumbnailImagePath(fullImg, fullImg);
    chooseImage(indexValue, heroImage);
  };
  const angleImages = [...slidesList.slice(1, 4)];

  const checkImageExist = async (url: any) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200 && !response.data.toLowerCase().includes("invalid url")) {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const [imageExists, setImageExists] = useState({});

  useEffect(() => {
    const fetchImageExistence = async () => {
      const imageExistence = {};
      for (const [i, image] of angleImages.entries()) {
        imageExistence[i] = await checkImageExist(image.fullImage);
      }
      setImageExists(imageExistence);
    };

    fetchImageExistence();
  }, [slidesList]);
  return (
    <StyledCarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={slideHeight}
      visibleSlides={mobile ? 1 : visibleSlides}
      step={mobile ? 1 : visibleSlides}
      infinite={true}
      dragEnabled={mobile ? true : false}
      totalSlides={slidesList.length}
      className="pdp-thumbnail-slider"
      orientation={mobile ? "horizontal" : "vertical"}>
      <Slider className="product-slider">
        <>
          {slidesList.map((e: any, i: number) =>
            ((i == 1 || i == 2 || i == 3) && imageExists[i - 1]) || (i != 1 && i != 2 && i != 3) ? (
              <Slide
                index={i}
                key={i}
                onFocus={() => handleHeroImage(i, e.fullImage)}
                // style={{ display:'none' }}
                id={`angleSlide-${i}`}
                className="slide-carousel">
                <ProductImage
                  {...{
                    fullImage: commonUtil.getThumbnailImagePath(e.fullImage, e.fullImage),
                    alt: e.name,
                    isThumbnail: !mobile,
                    isSelected: i === index,
                    index: i,
                    handleImageError: () => handleErrorImage(i), // Passing the handleErrorImage function as a prop
                  }}
                />
              </Slide>
            ) : null
          )}
        </>
      </Slider>
      {mobile && <DotGroup />}

      {slidesList.length > 7 && !mobile && (
        <ButtonBack>
          <NorthIcon />
        </ButtonBack>
      )}
      {slidesList.length > 7 && !mobile && (
        <ButtonBack>
          <SouthIcon />
        </ButtonBack>
      )}
      {slidesList.length > 1 && mobile && (
        <>
          <ButtonBack>
            <ChevronLeftIcon />
          </ButtonBack>

          <ButtonNext>
            <ChevronRightIcon />
          </ButtonNext>
        </>
      )}
    </StyledCarouselProvider>
  );
};
