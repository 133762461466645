/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { StyledProductCard, StyledProgressPlaceholder } from "../../elements";

interface ProductRecommendationCardProps {
  seoUrl?: string;
  swatches: any[];
  thumbnail: string;
  name: string;
  ribbonads: any[];
  price: any;
  informMarketingOfClick: any;
  formattedPriceDisplay: any;
  isB2B: boolean;
  displayMsrp: any | null;
  displayOfferPrice?: any | null;
  displayListPrice?: any | null;
  displayStrikethroughPrice?: any | null;
  formattedPriceDisplayOffer?: any | null;
  formattedPriceDisplayList?: any | null;
  formattedPriceDisplayNull?: any | null;
  formattedPriceDisplayStrikethrough?: any | null;
}

/**
 * Display recommended product card
 * `@prop { any } props` Have following properties:
 * ` @property { string } seoUrl`: The product seo href.
 * ` @property { any[] } swatches`: The product styled swatches.
 * ` @property { string } thumbnail`: The product image path.
 * ` @property { string } name`: The product name.
 * ` @property { any } price`: The product price.
 * ` @property { any } informMarketingOfClick`: The onclick event for product marketing information.
 * ` @property { any } formattedPriceDisplay`: The display product formatted price.
 */

export const ProductRecommendationCard: React.FC<ProductRecommendationCardProps> = (props: any) => {
  const seoUrl = props.seoUrl;
  const swatches = props.swatches;
  const thumbnail = props.thumbnail;
  const name = props.name;
  const ribbonads = props.ribbonads;
  const price = props.price;
  const informMarketingOfClick = props.informMarketingOfClick;
  const formattedPriceDisplay = props.formattedPriceDisplay;
  const isB2B = props.isB2B;
  const displayMsrp = props.displayMsrp;
  const displayOfferPrice = props.displayOfferPrice;
  const displayStrikethroughPrice = props.displayStrikethroughPrice;
  const displayListPrice = props.displayListPrice;
  const formattedPriceDisplayOffer = props.formattedPriceDisplayOffer;
  const formattedPriceDisplayList = props.formattedPriceDisplayList;
  const formattedPriceDisplayNull = props.formattedPriceDisplayNull;
  const formattedPriceDisplayStrikethrough = props.formattedPriceDisplayStrikethrough;
  const availableSizes = props.availableSizes;
  const page = props.page;
  const loginStatus = props.loginStatus;
  const addToWLButton = props.addToWLButton;
  const product = props.product;
  const thumbnailLoading = props.thumbnailLoading;

  return (
    <LazyLoadComponent
      visibleByDefault={(window as any).__isPrerender__ || false}
      placeholder={<StyledProgressPlaceholder className="vertical-padding-20" />}>
      <StyledProductCard
        {...{
          product,
          loginStatus,
          addToWLButton,
          seoUrl,
          swatches,
          thumbnail,
          thumbnailLoading,
          name,
          ribbonads,
          price,
          informMarketingOfClick,
          formattedPriceDisplay,
          isB2B,
          displayMsrp,
          displayOfferPrice,
          displayListPrice,
          displayStrikethroughPrice,
          formattedPriceDisplayOffer,
          formattedPriceDisplayList,
          formattedPriceDisplayNull,
          availableSizes,
          formattedPriceDisplayStrikethrough,
          page,
        }}
      />
    </LazyLoadComponent>
  );
};
