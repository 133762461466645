import React from "react";

class RatingSummary extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    $BV.configure("global", { productId: this.props.productId });
    $BV.ui("rr", "show_reviews", {
      doShowContent: function () {
        console.log("workin");
      },
    });
    return <div id="BVRRSummaryContainer"></div>;
  }
}
export default RatingSummary;
