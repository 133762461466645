import React, { createContext, useContext, useReducer } from "react";

export const QuickInfoContext: React.Context<any> = createContext({});

export interface QuickInfoStateProp {
  quickInfoProduct?: any;
}

const quickInfoState: QuickInfoStateProp = {
  quickInfoProduct: null,
};

const QuickInfoReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        quickInfoProduct: action.payload,
      };

    default:
      return state;
  }
};

export const QuickInfoProvider = ({ children }: any) => {
  const [data, dispatch] = useReducer(QuickInfoReducer, quickInfoState);
  const value = React.useMemo(() => ({ data, dispatch }), [data]);

  return <QuickInfoContext.Provider value={value}>{children}</QuickInfoContext.Provider>;
};

export const useQuickInfoValue = () => useContext<QuickInfoStateProp>(QuickInfoContext);
