/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentResponseForm } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceToolsOptoolsOrderBeansCSROrderSearchDataBeanIBMSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceToolsOptoolsOrderBeansOrderCommentRestDataBeanIBMSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { OrderOrderDetail } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { OrderOrderHistoryResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { OrderOrderSummary } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds CSR comments to the order.
         * @summary Add comments (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} [mode] CSR add comment mode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm} [body] CSR comment body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAddCSROrderComments: async (storeId: string, orderId: string, mode?: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderAddCSROrderComments', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderAddCSROrderComments', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/order/{orderId}/comment`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies an order for a specified external order ID.
         * @summary Copy by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCopyOMSOrderByExternalOrderId: async (storeId: string, extOrderId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderCopyOMSOrderByExternalOrderId', 'storeId', storeId)
            // verify required parameter 'extOrderId' is not null or undefined
            assertParamExists('orderCopyOMSOrderByExternalOrderId', 'extOrderId', extOrderId)
            const localVarPath = `/store/{storeId}/order/copy_oms_order/{extOrderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"extOrderId"}}`, String(extOrderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the order history for the authenticated user.
         * @summary Delete order history
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDeleteOrderHistory: async (storeId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderDeleteOrderHistory', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('orderDeleteOrderHistory', 'userId', userId)
            const localVarPath = `/store/{storeId}/order/@history`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of orders by buyer ID.
         * @summary Get orders by buyer ID
         * @param {string} storeId The store identifier.
         * @param {string} buyerId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByBuyerId: async (storeId: string, buyerId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindByBuyerId', 'storeId', storeId)
            // verify required parameter 'buyerId' is not null or undefined
            assertParamExists('orderFindByBuyerId', 'buyerId', buyerId)
            const localVarPath = `/store/{storeId}/order/byBuyerId/{buyerId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"buyerId"}}`, String(buyerId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of orders by a merchant assigned order reference number (ORMOrder).
         * @summary Get orders by order reference number
         * @param {string} storeId The store identifier.
         * @param {string} oRMOrder The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByORMOrder: async (storeId: string, oRMOrder: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindByORMOrder', 'storeId', storeId)
            // verify required parameter 'oRMOrder' is not null or undefined
            assertParamExists('orderFindByORMOrder', 'oRMOrder', oRMOrder)
            const localVarPath = `/store/{storeId}/order/byORMOrder/{ORMOrder}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"ORMOrder"}}`, String(oRMOrder));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the order details for a specific order ID.
         * @summary Get order details by order ID
         * @param {string} orderId The order identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByOrderId: async (orderId: string, storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderFindByOrderId', 'orderId', orderId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindByOrderId', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/order/{orderId}`
                .replace(`{${"orderId"}}`, String(orderId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of orders by order status.
         * @summary Get orders by status
         * @param {string} storeId The store identifier.
         * @param {string} status The order status.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByStatus: async (storeId: string, status: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindByStatus', 'storeId', storeId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('orderFindByStatus', 'status', status)
            const localVarPath = `/store/{storeId}/order/byStatus/{status}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"status"}}`, String(status));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets order details for a specified external order ID.
         * @summary Get details by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindOMSOrderDetailsByExternalOrderId: async (storeId: string, extOrderId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindOMSOrderDetailsByExternalOrderId', 'storeId', storeId)
            // verify required parameter 'extOrderId' is not null or undefined
            assertParamExists('orderFindOMSOrderDetailsByExternalOrderId', 'extOrderId', extOrderId)
            const localVarPath = `/store/{storeId}/order/oms_order/{extOrderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"extOrderId"}}`, String(extOrderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the order history for the authenticated user.
         * @summary Get order history
         * @param {string} storeId The store identifier.
         * @param {string} [userId] The user identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindOrderHistory: async (storeId: string, userId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderFindOrderHistory', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/order/@history`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds order comments for the specific order.
         * @summary Get order comments
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {boolean} [isAsc] Returned order comments sorted in ascending order.
         * @param {string} [orderByField] The order by field name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderCommentsByOrderId: async (storeId: string, orderId: string, isAsc?: boolean, orderByField?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderGetOrderCommentsByOrderId', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderCommentsByOrderId', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/order/{orderId}/comment`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isAsc !== undefined) {
                localVarQueryParameter['isAsc'] = isAsc;
            }

            if (orderByField !== undefined) {
                localVarQueryParameter['orderByField'] = orderByField;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows CSRs to find orders to work on behalf of a shopper.
         * @summary Get order by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name &#x3D; IBM_Summary.
         * @param {string} [extOrderId] The external order identifier for findByStatusExt query.
         * @param {string} [orderItemId] The order item identifier used by findChildOrderByOrderItemId query.
         * @param {string} [orderId] The order Id.
         * @param {string} [orderByTableName] The order by table name.
         * @param {string} [orderByFieldName] The order by field name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [retrievePendingGuestOrders] For CSR. Whether to retrieve pending guest orders or to exclude them. When set to true, only pending orders placed by guest users will be returned. Default value is false. This parameter will be ignored when the \&#39;status\&#39; parameter is set.
         * @param {string} [status] The order status. If \&#39;status\&#39; is set, the \&#39;retrievePendingGuestOrders\&#39; parameter will be ignored.
         * @param {string} [maxResults] The maximum number of results to return.
         * @param {string} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [recordSetTotal] The total number of records in set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderOrdersICanWorkonbehalf: async (storeId: string, q: string, profileName?: string, extOrderId?: string, orderItemId?: string, orderId?: string, orderByTableName?: string, orderByFieldName?: string, startIndex?: string, retrievePendingGuestOrders?: string, status?: string, maxResults?: string, pageNumber?: string, pageSize?: string, recordSetTotal?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderOrdersICanWorkonbehalf', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('orderOrdersICanWorkonbehalf', 'q', q)
            const localVarPath = `/store/{storeId}/order`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (extOrderId !== undefined) {
                localVarQueryParameter['extOrderId'] = extOrderId;
            }

            if (orderItemId !== undefined) {
                localVarQueryParameter['orderItemId'] = orderItemId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (orderByTableName !== undefined) {
                localVarQueryParameter['orderByTableName'] = orderByTableName;
            }

            if (orderByFieldName !== undefined) {
                localVarQueryParameter['orderByFieldName'] = orderByFieldName;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (retrievePendingGuestOrders !== undefined) {
                localVarQueryParameter['retrievePendingGuestOrders'] = retrievePendingGuestOrders;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (recordSetTotal !== undefined) {
                localVarQueryParameter['recordSetTotal'] = recordSetTotal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes an order for a specified external order ID.
         * @summary Process by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderProcessOMSOrderByExternalOrderId: async (storeId: string, extOrderId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderProcessOMSOrderByExternalOrderId', 'storeId', storeId)
            // verify required parameter 'extOrderId' is not null or undefined
            assertParamExists('orderProcessOMSOrderByExternalOrderId', 'extOrderId', extOrderId)
            const localVarPath = `/store/{storeId}/order/process_oms_order/{extOrderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"extOrderId"}}`, String(extOrderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} paymentSystem The payment system which is used for ApplePay
         * @param {string} paymentConfigGroup The payment configuration group
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdMerchantGet: async (storeId: string, paymentSystem: string, paymentConfigGroup: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdMerchantGet', 'storeId', storeId)
            // verify required parameter 'paymentSystem' is not null or undefined
            assertParamExists('storeStoreIdMerchantGet', 'paymentSystem', paymentSystem)
            // verify required parameter 'paymentConfigGroup' is not null or undefined
            assertParamExists('storeStoreIdMerchantGet', 'paymentConfigGroup', paymentConfigGroup)
            const localVarPath = `/store/{storeId}/merchant`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentSystem !== undefined) {
                localVarQueryParameter['paymentSystem'] = paymentSystem;
            }

            if (paymentConfigGroup !== undefined) {
                localVarQueryParameter['paymentConfigGroup'] = paymentConfigGroup;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {string} [body] The request object for merchant validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdMerchantValidatePost: async (storeId: string, responseFormat?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdMerchantValidatePost', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/merchant/validate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds CSR comments to the order.
         * @summary Add comments (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} [mode] CSR add comment mode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm} [body] CSR comment body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderAddCSROrderComments(storeId: string, orderId: string, mode?: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentResponseForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderAddCSROrderComments(storeId, orderId, mode, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies an order for a specified external order ID.
         * @summary Copy by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCopyOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCopyOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the order history for the authenticated user.
         * @summary Delete order history
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderDeleteOrderHistory(storeId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderDeleteOrderHistory(storeId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of orders by buyer ID.
         * @summary Get orders by buyer ID
         * @param {string} storeId The store identifier.
         * @param {string} buyerId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindByBuyerId(storeId: string, buyerId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindByBuyerId(storeId, buyerId, responseFormat, pageNumber, pageSize, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of orders by a merchant assigned order reference number (ORMOrder).
         * @summary Get orders by order reference number
         * @param {string} storeId The store identifier.
         * @param {string} oRMOrder The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindByORMOrder(storeId: string, oRMOrder: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindByORMOrder(storeId, oRMOrder, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the order details for a specific order ID.
         * @summary Get order details by order ID
         * @param {string} orderId The order identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindByOrderId(orderId: string, storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindByOrderId(orderId, storeId, responseFormat, pageNumber, pageSize, currency, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of orders by order status.
         * @summary Get orders by status
         * @param {string} storeId The store identifier.
         * @param {string} status The order status.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindByStatus(storeId: string, status: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindByStatus(storeId, status, responseFormat, pageNumber, pageSize, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets order details for a specified external order ID.
         * @summary Get details by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindOMSOrderDetailsByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindOMSOrderDetailsByExternalOrderId(storeId, extOrderId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the order history for the authenticated user.
         * @summary Get order history
         * @param {string} storeId The store identifier.
         * @param {string} [userId] The user identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderFindOrderHistory(storeId: string, userId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderFindOrderHistory(storeId, userId, responseFormat, pageNumber, pageSize, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finds order comments for the specific order.
         * @summary Get order comments
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {boolean} [isAsc] Returned order comments sorted in ascending order.
         * @param {string} [orderByField] The order by field name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderCommentsByOrderId(storeId: string, orderId: string, isAsc?: boolean, orderByField?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceToolsOptoolsOrderBeansOrderCommentRestDataBeanIBMSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderCommentsByOrderId(storeId, orderId, isAsc, orderByField, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows CSRs to find orders to work on behalf of a shopper.
         * @summary Get order by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name &#x3D; IBM_Summary.
         * @param {string} [extOrderId] The external order identifier for findByStatusExt query.
         * @param {string} [orderItemId] The order item identifier used by findChildOrderByOrderItemId query.
         * @param {string} [orderId] The order Id.
         * @param {string} [orderByTableName] The order by table name.
         * @param {string} [orderByFieldName] The order by field name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [retrievePendingGuestOrders] For CSR. Whether to retrieve pending guest orders or to exclude them. When set to true, only pending orders placed by guest users will be returned. Default value is false. This parameter will be ignored when the \&#39;status\&#39; parameter is set.
         * @param {string} [status] The order status. If \&#39;status\&#39; is set, the \&#39;retrievePendingGuestOrders\&#39; parameter will be ignored.
         * @param {string} [maxResults] The maximum number of results to return.
         * @param {string} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [recordSetTotal] The total number of records in set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderOrdersICanWorkonbehalf(storeId: string, q: string, profileName?: string, extOrderId?: string, orderItemId?: string, orderId?: string, orderByTableName?: string, orderByFieldName?: string, startIndex?: string, retrievePendingGuestOrders?: string, status?: string, maxResults?: string, pageNumber?: string, pageSize?: string, recordSetTotal?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceToolsOptoolsOrderBeansCSROrderSearchDataBeanIBMSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderOrdersICanWorkonbehalf(storeId, q, profileName, extOrderId, orderItemId, orderId, orderByTableName, orderByFieldName, startIndex, retrievePendingGuestOrders, status, maxResults, pageNumber, pageSize, recordSetTotal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes an order for a specified external order ID.
         * @summary Process by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderProcessOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderProcessOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} paymentSystem The payment system which is used for ApplePay
         * @param {string} paymentConfigGroup The payment configuration group
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdMerchantGet(storeId: string, paymentSystem: string, paymentConfigGroup: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdMerchantGet(storeId, paymentSystem, paymentConfigGroup, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {string} [body] The request object for merchant validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdMerchantValidatePost(storeId: string, responseFormat?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdMerchantValidatePost(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * Adds CSR comments to the order.
         * @summary Add comments (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} [mode] CSR add comment mode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm} [body] CSR comment body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAddCSROrderComments(storeId: string, orderId: string, mode?: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentResponseForm> {
            return localVarFp.orderAddCSROrderComments(storeId, orderId, mode, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies an order for a specified external order ID.
         * @summary Copy by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCopyOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderCopyOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the order history for the authenticated user.
         * @summary Delete order history
         * @param {string} storeId The store identifier.
         * @param {string} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDeleteOrderHistory(storeId: string, userId: string, options?: any): AxiosPromise<OrderOrderHistoryResponse> {
            return localVarFp.orderDeleteOrderHistory(storeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of orders by buyer ID.
         * @summary Get orders by buyer ID
         * @param {string} storeId The store identifier.
         * @param {string} buyerId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByBuyerId(storeId: string, buyerId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): AxiosPromise<OrderOrderDetail> {
            return localVarFp.orderFindByBuyerId(storeId, buyerId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of orders by a merchant assigned order reference number (ORMOrder).
         * @summary Get orders by order reference number
         * @param {string} storeId The store identifier.
         * @param {string} oRMOrder The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByORMOrder(storeId: string, oRMOrder: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<OrderOrderDetail> {
            return localVarFp.orderFindByORMOrder(storeId, oRMOrder, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the order details for a specific order ID.
         * @summary Get order details by order ID
         * @param {string} orderId The order identifier.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByOrderId(orderId: string, storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, profileName?: string, options?: any): AxiosPromise<OrderOrderDetail> {
            return localVarFp.orderFindByOrderId(orderId, storeId, responseFormat, pageNumber, pageSize, currency, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of orders by order status.
         * @summary Get orders by status
         * @param {string} storeId The store identifier.
         * @param {string} status The order status.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindByStatus(storeId: string, status: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): AxiosPromise<OrderOrderSummary> {
            return localVarFp.orderFindByStatus(storeId, status, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets order details for a specified external order ID.
         * @summary Get details by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindOMSOrderDetailsByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderFindOMSOrderDetailsByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the order history for the authenticated user.
         * @summary Get order history
         * @param {string} storeId The store identifier.
         * @param {string} [userId] The user identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderFindOrderHistory(storeId: string, userId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): AxiosPromise<OrderOrderSummary> {
            return localVarFp.orderFindOrderHistory(storeId, userId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Finds order comments for the specific order.
         * @summary Get order comments
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {boolean} [isAsc] Returned order comments sorted in ascending order.
         * @param {string} [orderByField] The order by field name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderCommentsByOrderId(storeId: string, orderId: string, isAsc?: boolean, orderByField?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<ComIbmCommerceToolsOptoolsOrderBeansOrderCommentRestDataBeanIBMSummary> {
            return localVarFp.orderGetOrderCommentsByOrderId(storeId, orderId, isAsc, orderByField, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows CSRs to find orders to work on behalf of a shopper.
         * @summary Get order by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name &#x3D; IBM_Summary.
         * @param {string} [extOrderId] The external order identifier for findByStatusExt query.
         * @param {string} [orderItemId] The order item identifier used by findChildOrderByOrderItemId query.
         * @param {string} [orderId] The order Id.
         * @param {string} [orderByTableName] The order by table name.
         * @param {string} [orderByFieldName] The order by field name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [retrievePendingGuestOrders] For CSR. Whether to retrieve pending guest orders or to exclude them. When set to true, only pending orders placed by guest users will be returned. Default value is false. This parameter will be ignored when the \&#39;status\&#39; parameter is set.
         * @param {string} [status] The order status. If \&#39;status\&#39; is set, the \&#39;retrievePendingGuestOrders\&#39; parameter will be ignored.
         * @param {string} [maxResults] The maximum number of results to return.
         * @param {string} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [recordSetTotal] The total number of records in set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderOrdersICanWorkonbehalf(storeId: string, q: string, profileName?: string, extOrderId?: string, orderItemId?: string, orderId?: string, orderByTableName?: string, orderByFieldName?: string, startIndex?: string, retrievePendingGuestOrders?: string, status?: string, maxResults?: string, pageNumber?: string, pageSize?: string, recordSetTotal?: string, options?: any): AxiosPromise<ComIbmCommerceToolsOptoolsOrderBeansCSROrderSearchDataBeanIBMSummary> {
            return localVarFp.orderOrdersICanWorkonbehalf(storeId, q, profileName, extOrderId, orderItemId, orderId, orderByTableName, orderByFieldName, startIndex, retrievePendingGuestOrders, status, maxResults, pageNumber, pageSize, recordSetTotal, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes an order for a specified external order ID.
         * @summary Process by external order ID
         * @param {string} storeId The store identifier.
         * @param {string} extOrderId The external order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderProcessOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderProcessOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} paymentSystem The payment system which is used for ApplePay
         * @param {string} paymentConfigGroup The payment configuration group
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdMerchantGet(storeId: string, paymentSystem: string, paymentConfigGroup: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdMerchantGet(storeId, paymentSystem, paymentConfigGroup, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs the merchant validation
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {string} [body] The request object for merchant validation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdMerchantValidatePost(storeId: string, responseFormat?: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdMerchantValidatePost(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * Adds CSR comments to the order.
     * @summary Add comments (CSR)
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order identifier.
     * @param {string} [mode] CSR add comment mode.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm} [body] CSR comment body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderAddCSROrderComments(storeId: string, orderId: string, mode?: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerOrderHandlerCSRCommentForm, options?: any) {
        return OrderApiFp(this.configuration).orderAddCSROrderComments(storeId, orderId, mode, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies an order for a specified external order ID.
     * @summary Copy by external order ID
     * @param {string} storeId The store identifier.
     * @param {string} extOrderId The external order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderCopyOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any) {
        return OrderApiFp(this.configuration).orderCopyOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the order history for the authenticated user.
     * @summary Delete order history
     * @param {string} storeId The store identifier.
     * @param {string} userId The user identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderDeleteOrderHistory(storeId: string, userId: string, options?: any) {
        return OrderApiFp(this.configuration).orderDeleteOrderHistory(storeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of orders by buyer ID.
     * @summary Get orders by buyer ID
     * @param {string} storeId The store identifier.
     * @param {string} buyerId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindByBuyerId(storeId: string, buyerId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any) {
        return OrderApiFp(this.configuration).orderFindByBuyerId(storeId, buyerId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of orders by a merchant assigned order reference number (ORMOrder).
     * @summary Get orders by order reference number
     * @param {string} storeId The store identifier.
     * @param {string} oRMOrder The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindByORMOrder(storeId: string, oRMOrder: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return OrderApiFp(this.configuration).orderFindByORMOrder(storeId, oRMOrder, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the order details for a specific order ID.
     * @summary Get order details by order ID
     * @param {string} orderId The order identifier.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindByOrderId(orderId: string, storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, profileName?: string, options?: any) {
        return OrderApiFp(this.configuration).orderFindByOrderId(orderId, storeId, responseFormat, pageNumber, pageSize, currency, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of orders by order status.
     * @summary Get orders by status
     * @param {string} storeId The store identifier.
     * @param {string} status The order status.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindByStatus(storeId: string, status: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any) {
        return OrderApiFp(this.configuration).orderFindByStatus(storeId, status, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets order details for a specified external order ID.
     * @summary Get details by external order ID
     * @param {string} storeId The store identifier.
     * @param {string} extOrderId The external order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindOMSOrderDetailsByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any) {
        return OrderApiFp(this.configuration).orderFindOMSOrderDetailsByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the order history for the authenticated user.
     * @summary Get order history
     * @param {string} storeId The store identifier.
     * @param {string} [userId] The user identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderFindOrderHistory(storeId: string, userId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any) {
        return OrderApiFp(this.configuration).orderFindOrderHistory(storeId, userId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finds order comments for the specific order.
     * @summary Get order comments
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order identifier.
     * @param {boolean} [isAsc] Returned order comments sorted in ascending order.
     * @param {string} [orderByField] The order by field name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderCommentsByOrderId(storeId: string, orderId: string, isAsc?: boolean, orderByField?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return OrderApiFp(this.configuration).orderGetOrderCommentsByOrderId(storeId, orderId, isAsc, orderByField, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows CSRs to find orders to work on behalf of a shopper.
     * @summary Get order by query
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. The default profile name &#x3D; IBM_Summary.
     * @param {string} [extOrderId] The external order identifier for findByStatusExt query.
     * @param {string} [orderItemId] The order item identifier used by findChildOrderByOrderItemId query.
     * @param {string} [orderId] The order Id.
     * @param {string} [orderByTableName] The order by table name.
     * @param {string} [orderByFieldName] The order by field name.
     * @param {string} [startIndex] The starting index of the result.
     * @param {string} [retrievePendingGuestOrders] For CSR. Whether to retrieve pending guest orders or to exclude them. When set to true, only pending orders placed by guest users will be returned. Default value is false. This parameter will be ignored when the \&#39;status\&#39; parameter is set.
     * @param {string} [status] The order status. If \&#39;status\&#39; is set, the \&#39;retrievePendingGuestOrders\&#39; parameter will be ignored.
     * @param {string} [maxResults] The maximum number of results to return.
     * @param {string} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
     * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
     * @param {string} [recordSetTotal] The total number of records in set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderOrdersICanWorkonbehalf(storeId: string, q: string, profileName?: string, extOrderId?: string, orderItemId?: string, orderId?: string, orderByTableName?: string, orderByFieldName?: string, startIndex?: string, retrievePendingGuestOrders?: string, status?: string, maxResults?: string, pageNumber?: string, pageSize?: string, recordSetTotal?: string, options?: any) {
        return OrderApiFp(this.configuration).orderOrdersICanWorkonbehalf(storeId, q, profileName, extOrderId, orderItemId, orderId, orderByTableName, orderByFieldName, startIndex, retrievePendingGuestOrders, status, maxResults, pageNumber, pageSize, recordSetTotal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes an order for a specified external order ID.
     * @summary Process by external order ID
     * @param {string} storeId The store identifier.
     * @param {string} extOrderId The external order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderProcessOMSOrderByExternalOrderId(storeId: string, extOrderId: string, responseFormat?: string, options?: any) {
        return OrderApiFp(this.configuration).orderProcessOMSOrderByExternalOrderId(storeId, extOrderId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs the merchant validation
     * @param {string} storeId The store identifier.
     * @param {string} paymentSystem The payment system which is used for ApplePay
     * @param {string} paymentConfigGroup The payment configuration group
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public storeStoreIdMerchantGet(storeId: string, paymentSystem: string, paymentConfigGroup: string, responseFormat?: string, options?: any) {
        return OrderApiFp(this.configuration).storeStoreIdMerchantGet(storeId, paymentSystem, paymentConfigGroup, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs the merchant validation
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
     * @param {string} [body] The request object for merchant validation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public storeStoreIdMerchantValidatePost(storeId: string, responseFormat?: string, body?: string, options?: any) {
        return OrderApiFp(this.configuration).storeStoreIdMerchantValidatePost(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
