/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import React, { useMemo, useState, useContext, useEffect } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

//Custom libraries
import styled from "styled-components";
import { StyledBox } from "../box";
import { StyledProgressPlaceholder } from "../circular-progress";
import { StyledTypography } from "../typography";
import { StyledCard, StyledCardMedia } from "../card";
import { StyledCheckbox } from "../check-box";
import { StyledFormControlLabel } from "../form";
import { StyledLink } from "../link";
import { RibbonAd } from "../../components/ribbon-ad";
import { StyledButton } from "../button";
import { eventNames } from "cluster";
import { values } from "lodash-es";
import { upperCase } from "lodash-es";

import { QuickInfoContext } from "../../commerce-widgets/catalog-entry-list/quickinfo-context";
import { Hidden } from "@material-ui/core";

const StyledProductCard = styled(({ className, ...props }) =>
  props.to ? <StyledLink className={className} {...props} /> : <StyledBox className={className} {...props} />
)`
  ${({ theme }) => `
    display: block;

    >.product-card.w-compare-box {
      height: calc(100% + 2.625rem);
    }

    &.product-card {
      flex: 1;
    }

    &.product-grid {
      .MuiCard-root {
        height: 100%;
      }
    }

  `}
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > label {
    > span.compare-checkbox {
      justify-content: flex-start;
    }
  }
`;

const CompareBox = ({ compare, product, catentryId }) => {
  const { type: t, product_number } = product;
  const visibility = t === "product" || t === "item" || t === "variant" ? "visible" : "hidden";
  return (
    <div style={{ marginLeft: "16px", visibility }}>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            data-testid={`product-compare-${product_number.toLowerCase()}-checkbox`}
            className="compare-checkbox"
            disabled={!!(!compare.data.checked[catentryId] && compare.data.disabled)}
            checked={!!compare.data.checked[catentryId]}
            onChange={(e) => compare.onChange(e, catentryId, product)}
          />
        }
        label={<StyledTypography variant="caption">{compare.t(compare.labels.addTo)}</StyledTypography>}
      />
    </div>
  );
};

interface ProductCardProps {
  seoUrl: any;
  name: any;
  ribbonads: any[];
  thumbnail: any;
  thumbnailLoading?: boolean;
  //price?: number | null;
  price?: any | null;
  displayMsrp: any | null;
  displayOfferPrice?: any | null;
  displayListPrice?: any | null;
  displayStrikethroughPrice?: any | null;
  formattedPriceDisplayOffer?: any | null;
  formattedPriceDisplayList?: any | null;
  formattedPriceDisplayNull?: any | null;
  formattedPriceDisplayStrikethrough: any | null;
  swatches: any[];
  catentryId?: string;
  onClick?: any;
  actions?: any;
  className?: any;
  categoryId?: string;
  formattedPriceDisplay: any;
  link?: any;
  compare?: any;
  product?: any;
  isB2B: boolean;
  availableSizes: any[];
  page: string;
  loginStatus: boolean;
  addToWLButton: any;
  quickInfoPopup?: any;
  addToCart?: any;
  clearProductCache?: any;
  quickOrderPopup?: any;
}

/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */
function ProductCard(props: ProductCardProps) {
  const {
    seoUrl,
    name,
    ribbonads,
    thumbnail,
    categoryId = "",
    thumbnailLoading = false,
    price = null,
    displayMsrp,
    displayOfferPrice,
    displayListPrice,
    displayStrikethroughPrice,
    formattedPriceDisplayOffer,
    formattedPriceDisplayList,
    formattedPriceDisplayNull,
    formattedPriceDisplayStrikethrough,
    swatches,
    onClick,
    catentryId = "",
    formattedPriceDisplay,
    actions: cardActions = null,
    link = null,
    compare,
    product,
    availableSizes,
    loginStatus,
    addToWLButton,
    quickInfoPopup,
    addToCart,
    clearProductCache,
    quickOrderPopup,
    page,
  } = props;

  const to = "/" + useMemo(() => link?.pathname ?? seoUrl, [link, seoUrl]);
  const toPDP = useMemo(() => link?.pathname ?? seoUrl, [link, seoUrl]);
  const linkState = useMemo(() => link?.state ?? { categoryId }, [link, categoryId]);
  const onClickAction = useMemo(() => (onClick ? { onClick } : {}), [onClick]);

  const { data, dispatch } = useContext(QuickInfoContext);

  //show onerror image
  function onError(e) {
    const imgonerror = e.target.src.replace("_aws_640.jpg", ".jpg");
    e.target.src = imgonerror;
    //console.log('errored', e.target.src)
  }
  const [show, setShow] = React.useState(false);
  const handelBtn = (e) => {
    // e.stopPropagation();
    // OR
    e.preventDefault();
  };

  const handelQuickOrderBtn = (e) => {
    e.preventDefault();
    // clearProductCache();
    quickInfoPopup();
  };
  const handelAddToCartBtn = (e) => {
    e.preventDefault();
    addToCart();
  };

  //custom quantity input
  let [num, setNum] = useState(0);
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };
  //custom quantity input end
  // hide 0 rating
  useEffect(() => {
    const hideProductRating = () => {
      const productRatingElements = document.querySelectorAll(".product-rating");
      productRatingElements.forEach((element) => {
        const ratingCountElement = element.querySelector(".bv-rating-ratio-count");
        const ratingCount = ratingCountElement?.textContent?.trim();
        if (ratingCount === "(0)") {
          (element as HTMLElement).style.display = "none";
        }
      });
    };
    // console.log(product, "productL");
    setTimeout(function () {
      hideProductRating();
    }, 3000);
  }, []);

  //quick view modal
  const [open, setOpen] = React.useState<boolean>(false);
  const handleQuickView = (e, product) => {
    e.preventDefault();
    setOpen(true);
    dispatch({ type: "UPDATE", payload: { product: product, open: true } });
  };
  const QuickViewClose = (e) => {
    e.preventDefault();
    setOpen(false);
    dispatch({ type: "UPDATE", payload: { product: product, open: false } });
  };

  const contentComponent = (
    <>
      {thumbnailLoading ? (
        <StyledProgressPlaceholder className="vertical-padding-8" />
      ) : (
        <LazyLoadComponent visibleByDefault={(window as any).__isPrerender__ || false}>
          {/* <StyledCardMedia image={thumbnail} title={name} component="div" className="thumbnail-image"></StyledCardMedia> */}

          <div className="thumb-image-wrap">
            {/* <img src={thumbnail} alt={name} className="thumbnail-image" onError={(e) => onError(e)}></img> */}
            {thumbnail === undefined ? (
              <img src={thumbnail} alt={name} className="thumbnail-image" onError={(e) => onError(e)}></img>
            ) : (
              <img
                src={thumbnail.replace(".jpg", "_aws_640.jpg")}
                alt={name}
                className="thumbnail-image"
                onError={(e) => onError(e)}></img>
            )}
            {/* <img alt={name} src="https://static.augustasportswear.com/product/222591_302_lquarter_aws_640.jpg" className="thumbnail-image"></img> */}
          </div>
        </LazyLoadComponent>
      )}
      {/* {loginStatus ? (
        <div className="product-wishlist">
          <StyledTypography variant="body1" align="left" className="bottom-margin-1 wrapText product-name">
            {addToWLButton}
          </StyledTypography>
        </div>
      ) : null} */}
      <div className="product-footer top-margin-1">
        <div className="product-info-section">
          <Hidden smDown>
            <div className="quick-cart desk-quick-view" onClick={handelBtn}>
              <StyledButton
                testId="quick-view-dialog-open"
                size="small"
                color="primary"
                fullWidth
                onClick={(e) => handleQuickView(e, product)}>
                Quick view
              </StyledButton>
            </div>
          </Hidden>
        </div>
        <div className="product-brand bottom-margin-1">
          <div className="brand">
            {(product?.brand && `${product?.brand}`.toUpperCase() === "AUGUSTA") ||
            (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "AUGUSTA") ? (
              <img src="//static.augustasportswear.com/static/Icon-Listing-AugustaSportswear-color.png" alt="Augusta" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "HIGH FIVE") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "HIGH FIVE") ? (
              <img src="//static.augustasportswear.com/static/Icon-Listing-HighFive-color.png" alt="High Five" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "HOLLOWAY") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "HOLLOWAY") ? (
              <img src="//static.augustasportswear.com/static/Icon-Listing-Holloway-color.png" alt="Holloway" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "RUSSELL") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "RUSSELL") ? (
              <img src="//static.augustasportswear.com/static/Icon-Listing-RussellAthletic-color.png" alt="Russell" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "PACIFIC HEADWEAR") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "PACIFIC HEADWEAR") ? (
              <img
                src="//static.augustasportswear.com/static/Icon-Listing-PacificHeadwear-color.png"
                alt="Pacific Headwear"
              />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "ALL VOLLEYBALL") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "ALL VOLLEYBALL") ? (
              <img src="//static.augustasportswear.com/static/Icon-Listing-CustomFuze-color.png" alt="Custom Fuze" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "CCM") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "CCM") ? (
              <img src="//static.augustasportswear.com/static/ccm_50x16.svg" alt="CCM" />
            ) : (product?.brand && `${product?.brand}`.toUpperCase() === "PG") ||
              (product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "PG") ? (
              <img src="https://static.augustasportswear.com/static/pg_24x24.svg" alt="PG" />
            ) : null}
          </div>
          <StyledTypography variant="body2" align="left" className="wrapText product-name" title={name}>
            {name}
          </StyledTypography>
        </div>
        <div className="rating-styleid">
          <StyledTypography variant="body2" align="left" className="wrapText product-styleId">
            {product?.product_number
              ? `#${product.product_number} `
              : product?.partNumber
              ? `#${product.partNumber}`
              : null}
          </StyledTypography>
          <div className="product-rating bottom-margin-1">
            {product?.product_number ? (
              <div id={"BVRRInlineRating-" + product.product_number}></div>
            ) : product?.partNumber ? (
              <div id={"BVRRInlineRating-" + product.partNumber}></div>
            ) : null}
          </div>
        </div>
        <div className="price-styleid bottom-margin-1">
          <div className="product-price-section">
            {displayListPrice > 0 && <span className="product-price">{formattedPriceDisplayList}</span>}
            {/* {displayOfferPrice > 0 && <span className="product-price">{formattedPriceDisplayOffer}</span>} */}
            {displayListPrice > 0 && displayListPrice < displayStrikethroughPrice && (
              <span id={`product_price_${catentryId}`} className="strikethrough left-margin-1">
                {formattedPriceDisplayStrikethrough}
              </span>
            )}
            {displayListPrice > 0 && displayListPrice < displayStrikethroughPrice && (
              <span className="offer-percent left-margin-1">
                &#40;{(((displayStrikethroughPrice - displayListPrice) / displayStrikethroughPrice) * 100).toFixed(2)}%
                off&#41;
              </span>
            )}
            {displayListPrice === 0 && (
              <span id={`product_offer_price_${catentryId}`}>{formattedPriceDisplayNull}</span>
            )}
          </div>
        </div>

        {/* {displayMsrp > 0 && displayOfferPrice == 0 && displayOfferPrice == 0 && price && (
          <div className="product-price-section">
            <StyledTypography variant="body1" align="left" className="product-price">
              {price}
            </StyledTypography>
            <StyledTypography>
              <span></span>
            </StyledTypography>
          </div>
        )}
        {displayMsrp == 0 && displayListPrice > 0 && <span className="product-price">{formattedPriceDisplayList}</span>}
        {/* {displayMsrp == 0  && displayOfferPrice > 0 && <span className="product-price">{formattedPriceDisplayOffer}</span>} */}
        {/* {displayMsrp == 0  && displayOfferPrice > 0 && displayOfferPrice < displayListPrice && (
                      <span id={`product_price_${catentryId}`} className="strikethrough">
                        {formattedPriceDisplayList}
                      </span>
                    )}
                    {displayMsrp == 0  && displayOfferPrice > 0 && displayOfferPrice < displayListPrice && (
                    <span className="offer-percent">&#40;{(displayListPrice-displayOfferPrice)/displayListPrice * 100}% off &#41;</span>
                    )} */}
        {/* {displayMsrp == 0 && displayListPrice === 0 && (
          <span id={`product_offer_price_${catentryId}`}>{formattedPriceDisplayNull}</span>
        )} */}
      </div>
      <div className="product-info-section product-info-hover">
        {/* <div className={`${show ? "arrow-right" : ""} quick-view-btn bottom-margin-2`}>
          <StyledButton onClick={handelBtn} testId="quick-view-btn" color="secondary">
            Quick View
          </StyledButton>
        </div> */}

        <div className="slides-hover">
          <div className="product-swatches" onClick={handelBtn}>
            {swatches.length > 0 && <StyledTypography align="center">{swatches}</StyledTypography>}
          </div>
          {/* <div onClick={handelBtn}>
            <div className="product-sizes top-margin-1 bottom-margin-1 top-padding-1 bottom-padding-1 text-center">
              {availableSizes && availableSizes?.map((size: any, index: number) => <span>{size}</span>)}
            </div>
          </div> */}
          <div className="qty-cart-btn">
            {/* <div className="custom-qty-input">
              <div className="qty-inner-box">
                <button className="btn-minus" type="button" onClick={decNum}>
                  <span className="material-icons-outlined">remove</span>
                </button>
                <input type="text" className="qty-input" value={num} onChange={handleChange} />
                <button className="btn-plus" type="button" onClick={incNum}>
                  <span className="material-icons-outlined">add</span>
                </button>
              </div>
            </div> */}
            {/* <div className="quick-cart">
              <StyledButton onClick={handelQuickOrderBtn} testId="quick-view-btn" color="primary">
                Quick View
              </StyledButton>

              <StyledButton onClick={handelAddToCartBtn} testId="quick-view-btn" color="primary">
                Add To Cart
              </StyledButton>
            </div> */}
          </div>
        </div>
      </div>
      <Hidden mdUp>
        <div className="quick-cart" onClick={handelBtn}>
          <StyledButton
            testId="quick-view-dialog-open"
            size="small"
            color="primary"
            onClick={(e) => handleQuickView(e, product)}>
            Quick view
          </StyledButton>
        </div>
      </Hidden>
    </>
  );

  const compareBox = useMemo(
    () => (compare ? <CompareBox {...{ compare, product, catentryId }} /> : <></>),
    [compare, product, catentryId]
  );

  return (
    <>
      <FlexWrapper className="product-image-wrap">
        <div className="product-image-wrap-inner">
          <div className="ribbonImage">
            {ribbonads[0] == "New Colors" ? (
              <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewColors.png" />
            ) : ribbonads[0] == "New Item" ? (
              <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewItem.png" />
            ) : ribbonads[0] == "New Sizes" ? (
              <img src="https://static.augustasportswear.com/static/Icon-Filter-Newsizes.png" />
            ) : ribbonads[0] == "Closeout" ? (
              <img src="https://static.augustasportswear.com/static/Icon-ProductListing-Sale.png" />
            ) : ribbonads[0] == "Best Seller" ? (
              <img src="https://static.augustasportswear.com/static/Icon-ProductListing-BestSeller.png" />
            ) : ribbonads[0] == "Decorated Styles" || ribbonads[0] == "Decorated" || ribbonads[0] == "Sample" ? (
              <img src="https://static.augustasportswear.com/static/Icon-Filter-SampleItem.png" />
            ) : ribbonads[0] == "Final Season" ? (
              <img src="https://static.augustasportswear.com/static/ASB_finalseason_icon.jpg" />
            ) : ribbonads[0] !== "" ? (
              ribbonads[0]
            ) : null}

            {/* {ribbonads.map(({ identifier, value }: any, i) => (
            <RibbonAd {...{ identifier, value, idx: i, key: i }} />
          ))} */}
          </div>
          <StyledProductCard
            className="product-card product-card-image"
            to={page === "CatalogEntryList" ? to : toPDP}
            state={linkState}
            id={catentryId ? `productCard_a_1_${catentryId}` : ""}
            {...onClickAction}>
            <StyledCard
              testId={catentryId}
              {...{
                className: `product-card ${compare ? "w-compare-box" : ""}`,
                contentComponent,
                cardActions,
              }}
            />
          </StyledProductCard>

          {/* {compareBox} */}
        </div>
      </FlexWrapper>
    </>
  );
}
export default ProductCard;
