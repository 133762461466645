/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
//UI
import {
  StyledGrid,
  StyledPDPContainer,
  StyledTypography,
  StyledButton,
  StyledTabs,
  StyledDialog,
  StyledDialogTitle,
  StyledLink,
  StyledCircularProgress,
  StyledDialogContent,
} from "../../elements";
import { QuickInfoContext } from "../../commerce-widgets/catalog-entry-list/quickinfo-context";
import { ProductAttributes } from "../../components/product-attributes";
import { ProductQuantity } from "../../components/product-quantity";
import { ProductThumbnails } from "../../components/product-thumbnails";
import Hidden from "@material-ui/core/Hidden";
import { get } from "lodash-es";
import { ProductImage } from "../../components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import RatingSummary from "../../components/bv/RatingSummary.js";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Box, Tab, Tabs } from "@material-ui/core";
interface ProductDetailsWidgetProps {
  seller: any;
  productPartNumber: any;
  product: any;
  showCarousel: boolean;
  carouselImages: any;
  changeMainImage: (index: number, image: string) => void;
  index: number;
  displayFullImage: string;
  displayName: string;
  displayPartNumber: string;
  displayShortDesc: string;
  promotion: any;
  displayOfferPrice: number;
  displayListPrice: number;
  displayStrikethroughPrice: number;
  definingAttrList: any;
  skuColor: string;
  onAttributeChange: (id: string, value: string) => void;
  currentSelection: any;
  updateProductQuantity: (n: number) => void;
  availability: any;
  addToCart: () => void;
  inventoryAvailableFlag: boolean;
  buyableFlag: boolean;
  productDetailTabsChildren: any;
  translation: any;
  formattedPriceDisplayOffer: any;
  formattedPriceDisplayList: any;
  formattedPriceDisplayStrikethrough: any;
  formattedPriceDisplayNull: any;
  loginStatus: boolean;
  isB2B: boolean;
  isSharedOrder: boolean;
  SIGNIN: string;
  addToRLButton: any;
  addToWLButton: any;
  formatLongDescription: (longDescription: string) => JSX.Element | null;
  getFabricContent: () => JSX.Element | null;
  availableAssoicationType: any;
}

export const ProductDetailsWidget: React.FC<ProductDetailsWidgetProps> = (props: any) => {
  const {
    seller,
    productPartNumber,
    product,
    showCarousel,
    carouselImages,
    changeMainImage,
    index,
    displayFullImage: fullImage,
    displayName,
    displayPartNumber,
    displayShortDesc,
    displayLongDesc,
    promotion,
    displayOfferPrice,
    displayListPrice,
    displayStrikethroughPrice,
    definingAttrList,
    skuColor,
    onAttributeChange,
    currentSelection,
    updateProductQuantity,
    availability,
    addToCart,
    inventoryAvailableFlag,
    buyableFlag,
    productDetailTabsChildren,
    translation,
    formattedPriceDisplayOffer,
    formattedPriceDisplayList,
    formattedPriceDisplayNull,
    formattedPriceDisplayStrikethrough,
    loginStatus,
    isB2B,
    isSharedOrder,
    SIGNIN,
    addToRLButton,
    addToWLButton,
    ribbonFinder,
    formatLongDescription,
    getFabricContent,
    fromPage,
    getProductAddedToCartMsgModal,
    success,
    MINICART_CONFIGS,
    availableAssoicationType,
    resetKeys,
    inventoryCount,
  } = props;
  const ribbonads = ribbonFinder(currentSelection.sku[currentSelection.index] ?? product);
  //console.log("carouselImages");
  //console.log(carouselImages);
  //Product details Accordion
  const [expanded, setExpanded] = React.useState<string | false>("panel3");
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { data, dispatch } = useContext(QuickInfoContext);
  const quickInfoproduct = data && data.quickInfoProduct ? data.quickInfoProduct.product : {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const skusAsAttrs = useMemo(() => {
    return (product?.items ?? []).map((p) => {
      const rc = {};
      p.attributes
        ?.filter(({ usage: u }) => u === "Defining")
        .forEach((a) => (rc[a.identifier] = get(a, "values[0].identifier")));
      return rc;
    });
  }, [product]);

  const [stickyClass, setStickyClass] = useState("sticky-nav");

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleClickOpen();
      }
    }
  }, [success]);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  const stickNavbar = () => {
    const positions = document.getElementById("product-img-height")?.offsetHeight;

    if (positions) {
      if (window.innerHeight + window.scrollY >= positions) {
        setStickyClass("sticky-absolute");
      } else {
        setStickyClass("sticky-nav");
      }
    }
  };
  const [heightClass, setheightClass] = useState(0);
  const [heightClasss, setheightClasss] = useState(0);
  useEffect(() => {
    const quickviewHeight = () => {
      setTimeout(() => {
        const divHeights = document.getElementById("pdp-rating-top")?.offsetHeight;
        console.log(divHeights, "offsetHeight");
        if (divHeights) {
          setheightClasss(divHeights);
        } else {
          setTimeout(() => {
            quickviewHeight();
          }, 5000);
        }
      }, 5000);
    };
    quickviewHeight();
  }, []);
  const AccordionHeight = (val) => {
    if ((val = "panel1a-header_0")) {
      setTimeout(() => {
        const divHeight = document.getElementById("accodion_Rating&Reviews")?.offsetHeight;
        //console.log(divHeight, "offsetHeight");
        if (divHeight) {
          setheightClass(divHeight);
        }
      }, 500);
    }
  };
  const [open, setOpen] = React.useState<boolean>(false);
  const [timerSeconds, setTimerSeconds] = useState(5);

  const handleClickOpen = () => {
    setOpen(true);
    setTimerSeconds(5);
  };

  const handleClose = () => {
    setOpen(false);
    resetKeys();
  };

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            setOpen(false);
            resetKeys();
            clearInterval(timer);
            if (fromPage === "plp-quickinfo" && dispatch && open) {
              dispatch({ type: "UPDATE", payload: { product: quickInfoproduct, open: false } });
            }
            return prevSeconds;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
  }, [open]);
  const [elementHeight, setelementHeight] = useState<number>();
  const handleClickScroll = (w) => {
    let element = document.getElementById(w)?.offsetTop;

    console.log(element);
    if (element) {
      console.log(element);
      element = element - 140;
      console.log(element);
      window.scroll({ top: element, behavior: "smooth" });
    }
  };
  setTimeout(() => {
    const boxes = Array.from(document.getElementsByClassName("bv-ask-question"));
    boxes.forEach((box) => {
      box.addEventListener("click", function handleClick(event) {
        document.getElementById("panel1a-header_1")?.click();
        document.getElementById("panel1a-header_1")?.scrollIntoView({ behavior: "smooth" });
      });
    });
  }, 5000);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const loading = product === null;

  const [openZoom, setOpenZoom] = React.useState<boolean>(false);
  const handleCloseZoom = () => {
    setOpenZoom(false);
  };
  const handleOpenZoom = () => {
    setOpenZoom(true);
  };

  //console.log(fullImage, "fullImage");
  const handleDownload = () => {
    fetch(fullImage)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary link to trigger the download
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: fullImage.split("?")[0].split("#")[0].split(".").pop() })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${fullImage.split("/").slice(-1).join().split(".").shift()}.${fullImage
            .split("?")[0]
            .split("#")[0]
            .split(".")
            .pop()}`
        );
        // Append the link to the DOM and simulate a click
        document.body.appendChild(link);
        link.click();
        // Clean up the URL object after the click
        window.URL.revokeObjectURL(url);
        // Remove the link from the DOM
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loader-icon-wpr">
          <StyledCircularProgress />
        </div>
      ) : null}
      {productPartNumber && product && (
        <StyledPDPContainer
          className="pdp-page-container"
          itemScope
          itemType="http://schema.org/Product"
          id={`product-image-details_${productPartNumber}`}>
          <StyledGrid container>
            {/* <StyledGrid container spacing={2} item xs={12} className="relative"> */}

            <StyledGrid item xs={12} md={6}>
              <StyledGrid container className="sticky-pdp-thumb">
                {showCarousel ? (
                  <>
                    {fromPage !== "plp-quickinfo" && (
                      <StyledGrid item xs={12} md={2}>
                        <ProductThumbnails {...{ imageList: carouselImages, changeMainImage, index }} />
                      </StyledGrid>
                    )}
                    {fromPage === "plp-quickinfo" && isMobile && (
                      <StyledGrid item xs={11} className="product-image">
                        <div className="ribbonImage">
                          {ribbonads[0] == "New Colors" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewColors.png" />
                          ) : ribbonads[0] == "New Item" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewItem.png" />
                          ) : ribbonads[0] == "New Sizes" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-Filter-Newsizes.png" />
                          ) : ribbonads[0] == "Closeout" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-Sale.png" />
                          ) : ribbonads[0] == "Best Seller" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-BestSeller.png" />
                          ) : ribbonads[0] == "Decorated Styles" ||
                            ribbonads[0] == "Decorated" ||
                            ribbonads[0] == "Sample" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-Filter-SampleItem.png" />
                          ) : ribbonads[0] == "Final Season" ? (
                            <img src="https://static.augustasportswear.com/static/ASB_finalseason_icon.jpg" />
                          ) : ribbonads[0] !== "" ? (
                            ribbonads[0]
                          ) : null}
                        </div>
                        <div className=" image-wrap w">
                          <ProductImage {...{ fullImage, alt: displayName }} />
                          {fromPage !== "plp-quickinfo" && (
                            <>
                              <StyledGrid container justifyContent="center" className="padding-10 margin-top-10 ">
                                <StyledGrid
                                  item
                                  onClick={handleDownload}
                                  className="flex align-end pointer padding-right-8 border-right">
                                  <span className="material-icons-outlined">file_download</span>
                                  <span>Download</span>
                                </StyledGrid>
                                <StyledGrid item onClick={handleOpenZoom} className="flex align-end pointer">
                                  <span className="material-icons-outlined">zoom_in</span>
                                  <span>Zoom</span>
                                </StyledGrid>
                              </StyledGrid>
                              <StyledDialog
                                open={openZoom}
                                onClose={handleCloseZoom}
                                className="open-zoom-popup"
                                aria-labelledby="change-password-success-dialog">
                                <StyledDialogTitle onClickHandler={handleCloseZoom} />
                                <StyledDialogContent className="bottom-margin-1 left-margin-1 right-margin-1 top-margin-1">
                                  <ProductImage {...{ fullImage, heroimage: true, alt: displayName }} />
                                </StyledDialogContent>
                              </StyledDialog>
                            </>
                          )}
                        </div>
                        <StyledLink className="link-text view-details" to={product?.seo.href}>
                          View Full details
                        </StyledLink>
                      </StyledGrid>
                    )}
                    <Hidden smDown>
                      <StyledGrid item xs={10} className="product-image">
                        <div className="ribbonImage">
                          {ribbonads[0] == "New Colors" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewColors.png" />
                          ) : ribbonads[0] == "New Item" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewItem.png" />
                          ) : ribbonads[0] == "New Sizes" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-Filter-Newsizes.png" />
                          ) : ribbonads[0] == "Closeout" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-Sale.png" />
                          ) : ribbonads[0] == "Best Seller" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-ProductListing-BestSeller.png" />
                          ) : ribbonads[0] == "Decorated Styles" ||
                            ribbonads[0] == "Decorated" ||
                            ribbonads[0] == "Sample" ? (
                            <img src="https://static.augustasportswear.com/static/Icon-Filter-SampleItem.png" />
                          ) : ribbonads[0] == "Final Season" ? (
                            <img src="https://static.augustasportswear.com/static/ASB_finalseason_icon.jpg" />
                          ) : ribbonads[0] !== "" ? (
                            ribbonads[0]
                          ) : null}
                        </div>
                        <div className="image-wrap x">
                          <ProductImage {...{ fullImage, heroimage: false, alt: displayName }} />

                          {fromPage !== "plp-quickinfo" && (
                            <>
                              <StyledGrid container justifyContent="center" className="padding-10 margin-top-10">
                                <StyledGrid
                                  item
                                  onClick={handleDownload}
                                  className="flex align-end pointer padding-right-8 border-right">
                                  <span className="material-icons-outlined">file_download</span>
                                  <span>Download</span>
                                </StyledGrid>
                                <StyledGrid item onClick={handleOpenZoom} className="flex align-end pointer">
                                  <span className="material-icons-outlined">zoom_in</span>
                                  <span>Zoom</span>
                                </StyledGrid>
                              </StyledGrid>
                              <StyledDialog
                                open={openZoom}
                                onClose={handleCloseZoom}
                                className="open-zoom-popup"
                                aria-labelledby="change-password-success-dialog">
                                <StyledDialogTitle onClickHandler={handleCloseZoom} />
                                <StyledDialogContent className="bottom-margin-1 left-margin-1 right-margin-1 top-margin-1">
                                  <ProductImage {...{ fullImage, heroimage: true, alt: displayName }} />
                                </StyledDialogContent>
                              </StyledDialog>
                            </>
                          )}
                        </div>
                        {fromPage === "plp-quickinfo" && (
                          <StyledLink className="link-text view-details" to={product?.seo.href}>
                            View Full details
                          </StyledLink>
                        )}
                        {/*fromPage !== "plp-quickinfo" && (
                           <StyledGrid
                             container
                             className="merchandising-link margin-top-20 pointer"
                             justifyContent="center">
                             {availableAssoicationType.map(
                               (type) =>
                                 (type === "COMPARABLE" && (
                                   <div className="padding-5p" onClick={() => handleClickScroll("similar-prod")}>
                                     <StyledGrid className="flex baseline">
                                       SIMILAR STYLES
                                       <span className="material-icons-outlined padding-left-5 font-size-13">south</span>
                                     </StyledGrid>
                                   </div>
                                 )) ||
                                 (type === "COLLECTIONS" && (
                                   <div className="padding-5p" onClick={() => handleClickScroll("coll-prod")}>
                                     <StyledGrid className="flex baseline">
                                       COLLECTION STYLES
                                       <span className="material-icons-outlined padding-left-5 font-size-13">south</span>
                                     </StyledGrid>
                                   </div>
                                 )) ||
                                 (type === "CO-ORDINATING" && (
                                   <div className="padding-5p" onClick={() => handleClickScroll("companion-prod")}>
                                     <StyledGrid className="flex baseline">
                                       COMPANION STYLES
                                       <span className="material-icons-outlined padding-left-5 font-size-13">south</span>
                                     </StyledGrid>
                                   </div>
                                 )) ||
                                 (type === "FREQUENTLYORDEREDWITH" && (
                                   <div className="padding-5p" onClick={() => handleClickScroll("freq-prod")}>
                                     <StyledGrid className="flex baseline">
                                       FREQUENTLY ORDERED WITH
                                       <span className="material-icons-outlined padding-left-5 font-size-13">south</span>
                                     </StyledGrid>
                                   </div>
                                 ))
                             )}
                           </StyledGrid>
                         )*/}
                      </StyledGrid>
                    </Hidden>
                    {/* <StyledGrid item xs={5} id="product-img-height">
                        {carouselImages.map((carouselImages) => {
                          return (
                            <>
                              <li>
                                <img src={carouselImages.thumbnail.replace(".jpg", "_aws_640.jpg")} alt="imgs"></img>
                              </li>
                            </>
                          );
                        })}
                      </StyledGrid> */}
                  </>
                ) : (
                  <StyledGrid item xs={12} md={12} className="product-image product-single-img">
                    <div className="ribbonImage">
                      {ribbonads[0] == "New Colors" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewColors.png" />
                      ) : ribbonads[0] == "New Item" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-ProductListing-NewItem.png" />
                      ) : ribbonads[0] == "New Sizes" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-Filter-Newsizes.png" />
                      ) : ribbonads[0] == "Closeout" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-ProductListing-Sale.png" />
                      ) : ribbonads[0] == "Best Seller" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-ProductListing-BestSeller.png" />
                      ) : ribbonads[0] == "Decorated Styles" ||
                        ribbonads[0] == "Decorated" ||
                        ribbonads[0] == "Sample" ? (
                        <img src="https://static.augustasportswear.com/static/Icon-Filter-SampleItem.png" />
                      ) : ribbonads[0] == "Final Season" ? (
                        <img src="https://static.augustasportswear.com/static/ASB_finalseason_icon.jpg" />
                      ) : ribbonads[0] !== "" ? (
                        ribbonads[0]
                      ) : null}
                    </div>
                    <div className="image-wrap s">
                      <ProductImage {...{ fullImage, heroimage: false, alt: displayName }} />
                      {fromPage !== "plp-quickinfo" && !isMobile && (
                        <>
                          <StyledGrid container justifyContent="center" className="padding-10 margin-top-10 ">
                            <StyledGrid
                              item
                              onClick={handleDownload}
                              className="flex align-end pointer padding-right-8 border-right">
                              <span className="material-icons-outlined">file_download</span>
                              <span>Download</span>
                            </StyledGrid>
                            <StyledGrid item onClick={handleOpenZoom} className="flex align-end pointer">
                              <span className="material-icons-outlined">zoom_in</span>
                              <span>Zoom</span>
                            </StyledGrid>
                          </StyledGrid>
                          <StyledDialog
                            open={openZoom}
                            onClose={handleCloseZoom}
                            className="open-zoom-popup"
                            aria-labelledby="change-password-success-dialog">
                            <StyledDialogTitle onClickHandler={handleCloseZoom} />
                            <StyledDialogContent className="bottom-margin-1 left-margin-1 right-margin-1 top-margin-1">
                              <ProductImage {...{ fullImage, heroimage: true, alt: displayName }} />
                            </StyledDialogContent>
                          </StyledDialog>
                        </>
                      )}
                    </div>
                    {fromPage === "plp-quickinfo" && (
                      <StyledLink className="link-text view-details" to={product?.seo.href}>
                        View Full details
                      </StyledLink>
                    )}
                  </StyledGrid>
                )}
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item xs={12} md={6}>
              <StyledGrid container className="pdp-top-section">
                <div className="brand">
                  {product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "AUGUSTA" ? (
                    <>
                      <img
                        src="//static.augustasportswear.com/static/Icon-Listing-AugustaSportswear-color.png"
                        alt="Augusta"
                      />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Augusta Sportswear
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "HIGH FIVE" ? (
                    <>
                      <img
                        src="//static.augustasportswear.com/static/Icon-Listing-HighFive-color.png"
                        alt="High Five"
                      />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        High Five
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "HOLLOWAY" ? (
                    <>
                      <img src="//static.augustasportswear.com/static/Icon-Listing-Holloway-color.png" alt="Holloway" />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Holloway
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "RUSSELL" ? (
                    <>
                      <img
                        src="//static.augustasportswear.com/static/Icon-Listing-RussellAthletic-color.png"
                        alt="Russell"
                      />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Russell
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "PACIFIC HEADWEAR" ? (
                    <>
                      <img
                        src="//static.augustasportswear.com/static/Icon-Listing-PacificHeadwear-color.png"
                        alt="Pacific Headwear"
                      />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Pacific Headwear
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "ALL VOLLEYBALL" ? (
                    <>
                      <img
                        src="//static.augustasportswear.com/static/Icon-Listing-CustomFuze-color.png"
                        alt="Custom Fuze"
                      />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Custom Fuze
                      </StyledTypography>
                    </>
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "CCM" ? (
                    <img src="//static.augustasportswear.com/static/ccm_50x16.svg" alt="CCM" />
                  ) : product?.manufacturer && `${product?.manufacturer}`.toUpperCase() === "PG" ? (
                    <>
                      <img src="https://static.augustasportswear.com/static/pg_24x24.svg" alt="PG" />
                      <StyledTypography variant="h4" className="brndNmeTxt">
                        Perfect Game
                      </StyledTypography>
                    </>
                  ) : null}
                </div>
              </StyledGrid>

              {displayName && (
                <StyledTypography variant="h4" itemProp="name" className="product-name bottom-margin-2">
                  {displayName}
                </StyledTypography>
              )}
              <div className="style-price-sec bottom-margin-2">
                {productPartNumber && (
                  <StyledTypography variant="body1" itemProp="name" className="product-style">
                    Style # {productPartNumber.includes(".") ? productPartNumber.split(".")[0] : productPartNumber}
                  </StyledTypography>
                )}
                <span className="divider left-margin-2 right-margin-2">&#124;</span>
                {product.type !== "bundle" && (
                  <StyledTypography className="product-price-container margin-left-2" variant="h5">
                    {displayListPrice > 0 && <span className="product-price">{formattedPriceDisplayList}</span>}
                    {/* {displayOfferPrice > 0 && <span className="product-price">{formattedPriceDisplayOffer}</span>} */}
                    {displayListPrice > 0 && displayListPrice < displayStrikethroughPrice && (
                      <span id={`product_price_${productPartNumber}`} className="strikethrough">
                        {formattedPriceDisplayStrikethrough}
                      </span>
                    )}
                    {displayListPrice > 0 && displayListPrice < displayStrikethroughPrice && (
                      <span className="offer-percent">
                        &#40;{" "}
                        {(((displayStrikethroughPrice - displayListPrice) / displayStrikethroughPrice) * 100).toFixed(
                          2
                        )}
                        % off&#41;
                      </span>
                    )}
                    {displayListPrice === 0 && (
                      <span id={`product_offer_price_${productPartNumber}`}>{formattedPriceDisplayNull}</span>
                    )}
                  </StyledTypography>
                )}
              </div>

              <div className="pdp-rating-top" id="pdp-rating-top" style={{ minHeight: heightClasss }}>
                <RatingSummary
                  productId={
                    productPartNumber.includes(".") ? productPartNumber.split(".")[0] : productPartNumber
                  }></RatingSummary>
              </div>
              {/* <StyledTypography className="shipping-box">
                 <span className="material-icons-outlined icon">local_shipping</span>
                 <span className="ship-text">Guaranteed same day shipping</span>
                 </StyledTypography>*/}

              {/* {displayPartNumber && (
                      <StyledTypography variant="body2" className="product-sku">
                        {translation.productDetailSKU}: {displayPartNumber}
                      </StyledTypography>
                    )} */}
              {/* {displayShortDesc && (
                      <StyledTypography variant="body1" itemProp="description" className="product-shortDescription">
                        {displayShortDesc}
                      </StyledTypography>
                    )} */}

              {/* <StyledGrid item xs={5} >
                      {definingAttrList[0].values.map(colorSwatches => {
                      return (
                      <>
                      <li>
                     <a  href="">{colorSwatches.value}</a>
                      </li>
                      </>
                      );
                      })}
                      </StyledGrid> */}
              {product.type !== "bundle" && (
                <>
                  {definingAttrList?.length > 0 && (
                    <ProductAttributes
                      skusAsAttrs={skusAsAttrs}
                      skuColor={skuColor}
                      attributeList={definingAttrList}
                      currentSelection={currentSelection}
                      onChangeHandler={onAttributeChange}
                      page={fromPage}
                      inventoryCount={inventoryCount}
                    />
                  )}
                </>
              )}
              {promotion && (
                <StyledTypography
                  variant="body2"
                  id={`product_advertisement_${productPartNumber}`}
                  className="product-promo"
                  gutterBottom>
                  {promotion}
                </StyledTypography>
              )}
              {/* {ribbonAds.length ? (
                      <div className="bottom-margin-2">
                        {ribbonAds.map((ribbon, i) => (
                          <ChipAd key={i} {...ribbon} />
                        ))}
                      </div>
                    ) : null} */}
              <div className="qty-cart-section">
                {product.type !== "bundle" && (
                  <>
                    <>
                      <StyledTypography variant="body2" className="product-quantity">
                        {translation.productDetailQuantity}
                      </StyledTypography>
                      <ProductQuantity
                        updateProductQuantity={updateProductQuantity}
                        value={currentSelection.quantity}
                      />
                      {/* {availability?.length > 0 && (
                              <>
                                <StyledTypography variant="body2" className="product-availability">
                                  {translation.productDetailAvailability}
                                </StyledTypography>
                                <StyledTypography variant="body1" component="div">
                                  {availability.map((e: any) => (
                                    <div
                                      key={`inventoryDetails_div_${e.onlineStoreId || e.physicalStoreId}_${e.partNumber}`}>
                                      {e.inventoryStatus ? (
                                        <StyledTypography
                                          key={`inStock_div_${e.partNumber}`}
                                          className="vertical-margin-1"
                                          id={`product_availability_status_inStock_${productPartNumber}`}>
                                          {e.physicalStoreId
                                            ? HTMLReactParser(translation.CommerceEnvironmentinventoryStoreStatusAvailable)
                                            : HTMLReactParser(translation.CommerceEnvironmentinventoryStatusAvailable)}
                                        </StyledTypography>
                                      ) : (
                                        <StyledTypography
                                          key={`outOfStock_div_${e.partNumber}`}
                                          className="vertical-margin-1"
                                          id={`product_availability_status_outOfStock_${productPartNumber}`}>
                                          {e.physicalStoreId
                                            ? HTMLReactParser(translation.CommerceEnvironmentinventoryStoreStatusOOS)
                                            : HTMLReactParser(translation.CommerceEnvironmentinventoryStatusOOS)}
                                        </StyledTypography>
                                      )}
                                    </div>
                                  ))}
                                </StyledTypography>
                              </>
                            )} */}
                      {/* {seller ? <div>{seller}</div> : null} */}
                      {/* <StyledGrid container spacing={1} alignItems="center" className="button-combo product-buy-now">
                              <StyledGrid item xs={12} sm="auto">
                                <StyledButton
                                  testId={`product_add_to_cart_${productPartNumber}`}
                                  color="primary"
                                  size="small"
                                  id={`product_add_to_cart_${productPartNumber}`}>
                                  Buy Now
                                </StyledButton>
                              </StyledGrid>
                            </StyledGrid> */}
                      <StyledGrid className="cart-whishlist-btn">
                        <StyledGrid
                          container
                          spacing={1}
                          alignItems="center"
                          className="button-combo product-add-to-cart">
                          <StyledGrid item xs={12} sm="auto">
                            <StyledButton
                              testId={`product_add_to_cart_${productPartNumber}`}
                              color="primary"
                              size="small"
                              id={`product_add_to_cart_${productPartNumber}`}
                              {...(!isB2B || loginStatus
                                ? {
                                    onClick: addToCart,
                                    disabled: !inventoryAvailableFlag || !buyableFlag || !displayOfferPrice,
                                  }
                                : { component: Link, to: SIGNIN })}>
                              {!isB2B
                                ? translation.productDetailAddToCart
                                : !loginStatus
                                ? translation.productDetailSignIn
                                : isSharedOrder
                                ? translation.productDetailaddToSharedOrder
                                : translation.productDetailaddToCurrentOrder}
                            </StyledButton>
                          </StyledGrid>
                          {!inventoryAvailableFlag && <span className="outOfStock">Out of Stock</span>}
                        </StyledGrid>

                        {/*loginStatus ? (
                           <div className="share-wishlist">
                             { <div className="share-section">
                            <a href="" title="">
                              <span className="material-icons-outlined">share</span>
                              <span>Share</span>
                            </a>
                          </div> }

                             <StyledGrid item xs={12} sm="auto">
                               {isB2B ? addToRLButton : addToWLButton}
                             </StyledGrid>
                           </div>
                         ) : null*/}
                      </StyledGrid>
                    </>
                  </>
                )}
              </div>

              <StyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="add-cart-dialog"
                className="order-addtocart">
                <StyledDialogTitle onClickHandler={handleClose} className="close-position" />
                {getProductAddedToCartMsgModal(timerSeconds)}
              </StyledDialog>

              {/* <StyledGrid container>
                      <StyledGrid item xs={12} md={6}>
                        <div className="select-offer">
                          <p className="title">Just for you</p>
                          <p>
                            Add a <span>Momentum tee</span> and save 20%{" "}
                            <a href="#" title="" className="link-text">
                              Learn more
                            </a>
                          </p>
                        </div>
                      </StyledGrid>
                      <StyledGrid item xs={12} md={6}>
                        <div className="select-offer">
                          <p className="title">Wear it with</p>
                          <p>
                            Add a <span>Momentum tee</span> and save 20%{" "}
                            <a href="#" title="" className="link-text">
                              Learn more
                            </a>
                          </p>
                        </div>
                      </StyledGrid>
                    </StyledGrid> */}
              {fromPage !== "plp-quickinfo" && (
                // <div className="product-desc-sec">
                //   <StyledTypography variant="body2" className="top-margin-3 bottom-margin-1 text-bold">
                //     Product Details
                //   </StyledTypography>
                //   {displayLongDesc.includes("*")
                //     ? formatLongDescription(displayLongDesc)
                //     : HTMLReactParser(displayLongDesc)}
                //   {getFabricContent()}
                // </div>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  className="pdp-details-accordin">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <StyledTypography variant="h6">Product Details</StyledTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {displayLongDesc.includes("*") && !displayLongDesc.includes("<ul")
                        ? formatLongDescription(displayLongDesc)
                        : HTMLReactParser(displayLongDesc)}
                      {getFabricContent()}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </StyledGrid>
          </StyledGrid>
        </StyledPDPContainer>
      )}
    </>
  );
};
